import CryptoJS from 'crypto-js'

const encrypt = (clearText: string) => {
    var ciphertext = CryptoJS.AES.encrypt(clearText, process.env.REACT_APP_CIPHER_KEY).toString();
    return ciphertext;
}

const decrypt = (encryptedText: string) => {
    var bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_CIPHER_KEY);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

export const Encrypter = {
    encrypt, 
    decrypt,
};