import React from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";

const Wrapper = styled(Flex)`
  position: absolute;
  top: 6.5vh;
  height: 70px;
  width: 90%;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 13px 50px;
  box-sizing: border-box;
  z-index: 150;

  //start - Support VA
  @media (max-width: 1160px) {
    height: 45px;
    bottom: 11.5rem;
    top: auto;
  }
  @media (max-width: 950px) {
    top: 6.5vh;
    bottom: auto;
  }
  //end - Support VA

  @media (max-height: 769px) {
    height: 40px;
  }
  @media (max-height: 501px) {
    height: 30px;
  }
  @media (max-height: 251px) {
    top: 5px;
    height: 10px;
    padding: 10px 50px;
  }
`;

const Message = styled.div`
  padding: 5px;
  font-size: 14px;
  color: #d84242;
  text-align: center;
  @media (max-height: 501px) {
    font-size: 12px;
  }
  @media (max-height: 381px) {
    font-size: 9px;
  }
  @media (max-height: 251px) {
    font-size: 6px;
  }
`;

const InfoAlert = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Wrapper>
      <Message>{message}</Message>
    </Wrapper>
  );
};
export default InfoAlert;
