import { createSlice } from '@reduxjs/toolkit'

export interface MeasureStatusState {
  finishMeasure: boolean
  loadingProcessingMeasureData: boolean,
  startMeasure: boolean,
  isStarted: boolean,
}

const initialState: MeasureStatusState = {
  finishMeasure: false,
  loadingProcessingMeasureData: false,
  startMeasure: false,
  isStarted: false,
}

export const measureStatusSlice = createSlice({
  name: 'measureStatus',
  initialState,
  reducers: {
    changeFinishMeasureState: (state, action) => {
      const finishMeasureState = action.payload;
      state.finishMeasure = finishMeasureState;
    },
    changeLoadingProcessingMeasureDataState: (state, action) => {
      const loadingProcessingMeasureDataState = action.payload;
      state.loadingProcessingMeasureData = loadingProcessingMeasureDataState;
    },
    //Turn true when the button start clicked, but after that, the value will change to false
    changeStartMeasureState: (state, action) => {
      const startMeasureState = action.payload;
      state.startMeasure = startMeasureState;
    },
    //Turn true when the button start clicked, but it will change to false when finish measure or measure is stopped.
    changeIsStartState: (state, action) => {
      const isStartedState = action.payload;
      state.isStarted = isStartedState;
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  changeFinishMeasureState,
  changeLoadingProcessingMeasureDataState,
  changeStartMeasureState,
  changeIsStartState,
} = measureStatusSlice.actions

export default measureStatusSlice.reducer