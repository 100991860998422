import React, { useEffect, useState } from "react";
import CardResult from "./CardResult";
import styled from "styled-components";
import { Flex } from "components/shared/Flex";
import DescriptionModal from "components/VitalResultCard/DescriptionModal";
import { UNKNOWN_DATA, rangeCheckingVitalSign } from "data/constant/vitalConstraint";
import { GetVitalConstantData, Selection } from "data/constant/vitalDescription";

const Card = styled(Flex)`
  background-color: #f8f9ff;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  @media (max-width: 700px) {
    height: 8rem;
  }
  /* @media (max-width: 480px){
    width: 46%;
    height: 8rem;
  }

  @media (max-width: 375px){
    height: 7rem;
  }

  @media (max-width: 320px){
    height: 5.5rem;
  }
  
  @media (max-width: 240px){
    margin: 2.5px;
    height: 3rem;
  } */
`;

const ClickableWrapper = styled.span`
  display: flex;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

export default function VitalSignCard({ vitalSign, data }) {
  const [result, setResult]: any = useState();
  //resolve the data
  //check the status
  useEffect(() => {
    var tempObj: any = {};
    tempObj["data"] = data == UNKNOWN_DATA.NaN ? "--" : data;
    tempObj["status"] = rangeCheckingVitalSign(vitalSign, data);

    tempObj[`${Selection.VitalUnit}`] = GetVitalConstantData(vitalSign, Selection.VitalUnit);

    tempObj[`${Selection.FullVitalName}`] = GetVitalConstantData(vitalSign, Selection.FullVitalName)

    tempObj[`${Selection.VitalResultDescription}`] = GetVitalConstantData(vitalSign, Selection.VitalResultDescription, tempObj["status"])

    tempObj[`${Selection.VitalDescription}`] =  GetVitalConstantData(vitalSign, Selection.VitalDescription)

    setResult(tempObj);
  }, [vitalSign, data]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <>
      {result && (
        <>
          <Card>
            <ClickableWrapper onClick={handleOpen}>
              <CardResult
                vitalSign={vitalSign}
                status={result.status}
                resultTitle={result[`${Selection.FullVitalName}`]}
                result={result.data}
                unit={result[`${Selection.VitalUnit}`]}
              />
            </ClickableWrapper>
          </Card>
          <DescriptionModal
            vitalSign={vitalSign}
            flag={open}
            setFlag={setOpen}
            status={result.status}
            description={result[`${Selection.VitalDescription}`]}
            resultTitle={result[`${Selection.FullVitalName}`]}
            result={result.data}
            unit={result[`${Selection.VitalUnit}`]}
          />
        </>
      )}
    </>
  );
}
