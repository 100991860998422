import { createSlice } from '@reduxjs/toolkit'

export interface KeyDurationSessionState {
  license: string,
  processingTime: string,
}

const initialState: KeyDurationSessionState = {
  license: "",
  processingTime: "",
}

export const keyDurationSessionSlice = createSlice({
  name: "keyDurationSession",
  initialState,
  reducers: {
    setKeySession: (state, action) => {
      const { license } = action.payload;
      state.license = license;
    },
    setDurationSession: (state, action) => {
      const { processingTime } = action.payload;
      state.processingTime = processingTime;
    },
  }
})

export const { setKeySession, setDurationSession } = keyDurationSessionSlice.actions;

export default keyDurationSessionSlice.reducer;