import Fade from '@mui/material/Fade';
import { Flex } from 'components/shared/Flex';
import { MeasuringText } from 'data/constant/vitalDescription';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
    height: 3rem;
    width: 100%;
    color: #646c7e;
    position: relative;
    justify-content: center;
    align-items: center;
`;

var intervalId: any;
export default function TransitionText({ isMeasuring }: any) {
  const processingTime = parseInt(useSelector((state: RootState) => state.keyDurationSession.processingTime));

  const [index, setIndex] = useState(-1);
  const [delayTime] = useState((processingTime / MeasuringText.length) * 1000);

  useEffect(() => {
    setIndex(0);
  }, [isMeasuring])

  useEffect(() => {
    intervalId = setInterval(() => {
      setIndex(index => index + 1);
    }, delayTime);
    return () => clearTimeout(intervalId);
  }, [isMeasuring]);

  return (
    <Wrapper>
      <Fade in={true}>
        <div>
          {index < MeasuringText.length ? MeasuringText[index] : (clearTimeout(intervalId), MeasuringText[MeasuringText.length - 1])}
        </div>
      </Fade>
    </Wrapper>
  )
}