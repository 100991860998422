import { configureStore } from "@reduxjs/toolkit";
import measureStatusSlice from "./measureStatus/measureStatusSlice";
import isExpireSessionSlice from "./isExpireSession/isExpireSessionSlice";
import keyDurationSessionSlice from "./keyDurationSession/keyDurationSessionSlice";
import measureSessionSlice from "./measureSession/measureSessionSlice";
import paramDataSlice from "./paramData/paramDataSlice";
import uploadFileSlice from "./uploadFile/uploadFileSession";
import popupStateManagementSlice from "./popupStateManagement/popupStateManagementSlice";

export const store = configureStore({
  reducer: {
    paramData: paramDataSlice,
    measureStatus: measureStatusSlice,
    isExpire: isExpireSessionSlice,
    keyDurationSession: keyDurationSessionSlice,
    measureSession: measureSessionSlice,
    uploadFile: uploadFileSlice,
    popupStateManagement: popupStateManagementSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
