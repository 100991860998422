import { Flex } from "components/shared/Flex";
import { useCameras } from "hooks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PrimasMonitor from "../../components/VitalMeasuringScreen/PrimasMonitor";
import { useDispatch, useSelector } from "react-redux";
import { UseQueryResult } from "react-query";
import { measureService } from "services/measureService.service";
import { env_server } from "data/env/envKey.env";
import { RootState } from "store/store";
import Loader from "components/VitalMeasuringScreen/Loader";
import PopupInstruction from "components/VitalPopupInstruction/PopupInstruction";

const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const Container = styled(Flex)`
  height: 100svh;
  width: 100vw;
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

export default function Measure() {
  const cameras = useCameras();
  const [cameraId, setCameraId] = useState<string>();
  // // const fullScreenStyle = {
  // //   height: `${window.innerHeight - 1}px`,
  // // };
  // useEffect(() => {
  //   if (!cameras?.length) return;
  //   setCameraId(cameras[0].deviceId);
  // }, [cameras]);

  const loadingProcessingMeasureData = useSelector((state: RootState) => state.measureStatus.loadingProcessingMeasureData)
  const { filter, autostart } = useSelector((state: RootState) => state.paramData);
  const dispatch = useDispatch();

  const {
    data: binahKey,
    status: binahKeyStatus,
    isFetching: binahKeyisFetching,
    error: binahKeyError,
  }: UseQueryResult<string, Error> = measureService.useGetSettingByKey(
    env_server.BINAH_KEY,
    dispatch
  );

  const {
    data: binahDuration,
    status: binahLicenseStatus,
    isFetching: binahDurationisFetching,
    error: binahLicenseError,
  }: UseQueryResult<string, Error> = measureService.useGetSettingByKey(
    env_server.BINAH_DURATION,
    dispatch,
    filter
  );

  useEffect(() => {
    if (!cameras?.length) return;
    setCameraId(cameras[0].deviceId);
  }, [cameras]);

  if (binahKeyisFetching || binahDurationisFetching) {
    return <></>;
  }

  return (
    // <Wrapper style={fullScreenStyle}>
    <Container>
      {!autostart && <PopupInstruction />}
      <PrimasMonitor cameraId={cameraId} />
      {loadingProcessingMeasureData && <Loader opacity={0.55} />}
    </Container>
    // </Wrapper>
  );
}
