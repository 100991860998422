import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { Flex } from "components/shared/Flex";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import sad from "../../assets/icon/result/sad-face.svg";
import happy from "../../assets/icon/result/happy-face.svg";
import { COLOR_KEY, STATUS_KEY } from "data/constant/vitalConstraint";
import { GetVitalConstantData, Selection } from "data/constant/vitalDescription";
// import { statusDescription } from "data/constant/vitalDescription";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "75%",
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  outline: 0,
};

const ResultText = styled(Flex)`
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  align-items: center;
  width: 100%;
  z-index: 1;
  font-weight: 700;
`;

const Result = styled.span`
  font-size: 3vh;
  font-weight: 600;
  align-self: center;
`;

const BPResult = styled.span`
  font-size: 2.2vh;
  font-weight: 600;
  align-self: center;
`;

const Unit = styled.span`
  font-size: 1.2vh;
  align-self: center;
  color: #494f5e;
`;

const ResultTitle = styled(Flex)`
  height: 35%;
  align-items: center;
  justify-content: center;
  font-size: 2.65vh;
  font-weight: 700;
  color: #494f5e;
  z-index: 1;
`;

const ResultWrapper = styled(Flex)`
  height: 23%;
  width: 100%;
  background-color: #e7fcfe;
  position: relative;
  flex-direction: column;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const Wrapper = styled.div`
  height: 70%;
  width: auto;
  padding: 5vh 1vh 1.2vh 1vh;
  position: relative;
`;


const DescriptionStatusWrapper = styled(Flex)`
  width: 100%;
  height: 8%;
  align-items: center;
  justify-content: center;
  `;

const DesTitle = styled(Flex)`
  font-size: 1.7vh;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  margin: 1vh 0;
  font-style: italic;
  color: #646c7e;
  `;

const NormalIconWrapper = styled(Flex)`
  height: 7vh;
  width: 7vh;
  left: 50%;
  transform: translateX(-50%);
  bottom: -21%;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #c1fae2;
  border: 2px solid #00ff91;
`;

const UnnormalIconWrapper = styled(Flex)`
  height: 7vh;
  width: 7vh;
  left: 50%;
  transform: translateX(-50%);
  bottom: -21%;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #ffd3d3;
  border: 2px solid #ff4949;
`;

const Range = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Img = styled.img`
  height: 100%;
`;

const DescriptionStatusDetail = styled.span`
  margin: 20px 10px;
  padding: 0 5px;
  display: flex;
  width: auto;
  height: 78%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7vh;
  text-align: left;
  overflow-y: auto;
  word-spacing: 1px;
  word-break: break-word;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // ::-webkit-scrollbar-track-piece:start {
  //   background: transparent;
  //   margin-top: 20px;
  // }

  // ::-webkit-scrollbar-track-piece:end {
  //   background: transparent;
  //   margin-bottom: 20px;
  // }
`;

interface DescriptionStatusProps {
  backgroundColor: String;
  textColor: String;
}

const DescriptionStatus = styled.div<DescriptionStatusProps>`
  padding: 0 10px 0 10px;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => `${props.backgroundColor}`};
  border-radius: 100px;
  font-size: 2vh;
  font-weight: 600;
  display: flex;
  color: ${(props) => `${props.textColor}`};
`;

interface BackgroundColorProps {
  status: String;
}

const BackgroundColor = styled.span<BackgroundColorProps>`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: ${(props) => {
    if (props.status == STATUS_KEY.NORMAL || props.status == STATUS_KEY.MEDIUM) {
      return `${COLOR_KEY.BACKGROUND_GREEN}`
    } else if (props.status == STATUS_KEY.HIGH || props.status == STATUS_KEY.LOW) {
      return `${COLOR_KEY.BACKGROUND_RED}`
    } else {
      return `${COLOR_KEY.BACKGROUND_UNKNOWN}`
    }
  }};
  z-index: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

export default function DescriptionModal({ vitalSign, flag, setFlag, status, description, resultTitle, result, unit }) {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");

  useEffect(() => {
    if (status === STATUS_KEY.NORMAL || status === STATUS_KEY.MEDIUM) {
      setBackgroundColor(COLOR_KEY.MODAL_BACKGROUND_GREEN);
      setTextColor(COLOR_KEY.MODAL_WORD_GREEN);
    } else if (status === STATUS_KEY.HIGH || status === STATUS_KEY.LOW) {
      setBackgroundColor(COLOR_KEY.MODAL_BACKGROUND_RED);
      setTextColor(COLOR_KEY.MODAL_WORD_RED);
    } else if (status === STATUS_KEY.UNKNOWN) {
      setBackgroundColor(COLOR_KEY.BACKGROUND_UNKNOWN);
      setTextColor(COLOR_KEY.MODAL_WORD_UNKNOWN);
    }
  }, [result]);

  return (
    <Modal open={flag} onClose={() => setFlag(false)} sx={{ fontFamily: "Rubik,Segoe UI,sans-serif" }}>
      <Box sx={style}>
        <ResultWrapper>
          <BackgroundColor status={status} />
          <ResultTitle>{resultTitle}</ResultTitle>
          <ResultText>
            {resultTitle == "Blood Pressure" ? <BPResult>{result}</BPResult> : <Result>{result}</Result>}
            <Unit>{unit}</Unit>
          </ResultText>
          {
            (status === STATUS_KEY.NORMAL || status === STATUS_KEY.MEDIUM) &&
            <NormalIconWrapper>
              <Img src={happy} />
            </NormalIconWrapper>
          }
          {
            (status === STATUS_KEY.HIGH || status === STATUS_KEY.LOW) &&
            <UnnormalIconWrapper>
              <Img src={sad} />
            </UnnormalIconWrapper>
          }
        </ResultWrapper>

        <Wrapper>

          <DescriptionStatusWrapper>
            <DescriptionStatus backgroundColor={backgroundColor} textColor={textColor}>{status.toUpperCase()}</DescriptionStatus>
          </DescriptionStatusWrapper>

          <DesTitle>
            {GetVitalConstantData(vitalSign, Selection.VitalRangeDescription) ? <Range>{GetVitalConstantData(vitalSign, Selection.VitalRangeDescription)}</Range>
              : <Range>{"--"}</Range>}
          </DesTitle>

          <DescriptionStatusDetail>
            <span
              style={{
                width: "100%",
              }}>
              {parse(description)}
            </span>
          </DescriptionStatusDetail>

        </Wrapper>
      </Box>
    </Modal>
  );
}
