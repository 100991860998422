import { Flex } from 'components/shared/Flex';
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components';

const HeartRateWrapper = styled.div`
    width: 250px;
    height: 35px;
    position: relative;
    margin: 10px auto;
    
    @media (max-width: 240px){
      width: 150px;
      height: 20px;
    }
`;
const heartRateIn = keyframes`
  0% {
    width: 100%;
  }
  50% {
    width: 0;
  }
  100% {
    width: 0;
  }
`;

const heartRateOut = keyframes`
  0% {
    width: 0;
  }
  50% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const FadeIn = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,1);
  top: 0;
  right: 0;
  animation: ${heartRateIn} 3s linear infinite;
  z-index: 6;
  `;

const FadeOut = styled.div`
 position: absolute;
  width: 120%;
  height: 100%;
  top: 0;
  left: 0%;
  animation: ${heartRateOut} 3s linear infinite;

  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);
  z-index: 6;
`;

const PictureWrapper = styled(Flex)`

`;

const Wrapper = styled.div`
    border-radius: 10px;
    width: 30rem;
`;

export default function HeartRate() {
  const [height, setHeight] = useState(35);
  const { innerWidth, innerHeight } = window;
  useEffect(() => {
    if (innerWidth <= 240) {
      setHeight(20);
    }
  }, [])

  return (
    <Wrapper>
      <HeartRateWrapper>
        <PictureWrapper>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="350px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73" style={{ zIndex: "5", height: `${height}px` }}>
            <polyline fill="none" stroke="#646c7e" strokeWidth="4" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
            />
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="350px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73" style={{ zIndex: "5", height: `${height}px` }}>
            <polyline fill="none" stroke="#646c7e" strokeWidth="4" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
            />
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="350px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73" style={{ zIndex: "5", height: `${height}px` }}>
            <polyline fill="none" stroke="#646c7e" strokeWidth="4" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
            />
          </svg>
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="350px" viewBox="0 0 150 73" enableBackground="new 0 0 150 73" style={{ zIndex: "5", height: `${height}px` }}>
            <polyline fill="none" stroke="#646c7e" strokeWidth="4" strokeMiterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"
            />
          </svg>
        </PictureWrapper>
        <FadeIn />
        <FadeOut />
      </HeartRateWrapper>
    </Wrapper>
  )
}
