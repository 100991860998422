import { createSlice } from '@reduxjs/toolkit'

export interface UploadFileState {
  isUploadPDF: boolean,
}

const initialState: UploadFileState = {
  isUploadPDF: false,
}

export const uploadFileSlice = createSlice({
  name: 'uploadFile',
  initialState,
  reducers: {
    enableUploadPDF: (state) => {
      state.isUploadPDF = true
    },
    disableUploadPDF: (state) => {
      state.isUploadPDF = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { enableUploadPDF, disableUploadPDF } = uploadFileSlice.actions

export default uploadFileSlice.reducer