import { createSlice } from '@reduxjs/toolkit'

export interface popupStateManagemenent {
  isReviewHidden: boolean
}

const initialState: popupStateManagemenent = {
  isReviewHidden: true,
}

export const popupStateManagementSlice = createSlice({
  name: 'popupStateManagement',
  initialState,
  reducers: {
    setIsReviewHidden: (state, action) => {
      const value = action.payload;
      state.isReviewHidden = value
    },
  },
})

// Action creators are generated for each case reducer function
export const { setIsReviewHidden } = popupStateManagementSlice.actions

export default popupStateManagementSlice.reducer