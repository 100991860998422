import { ReturnResult } from 'data/model/returnResult.model';
import React from 'react';
import { UseQueryResult } from 'react-query';
import { Navigate, Outlet } from 'react-router-dom';
import { initialService } from 'services/initialService.service';
import { IEnvServer } from 'data/interface/env_server.interface';
import { env_server } from 'data/env/envKey.env';

export default function GetSourceConfig() {
  const {
    data: configData,
    isFetching: configDataisFetching,
    isError: configDataisError,
  }: UseQueryResult<ReturnResult<IEnvServer>, Error> = initialService.useGetSourceConfig();


  if (configDataisError) {
    return <Navigate to="/404" />;
  }

  if (configDataisFetching) {
    return null;
  }

  if (Object.keys(configData.result).length == 0) {
    return <Navigate to="/404" />;
  } else {
    env_server.SOURCE = configData.result.SOURCE;
    env_server.BINAH_KEY = configData.result.BINAH_KEY;
    env_server.BINAH_DURATION = configData.result.BINAH_DURATION;
    env_server.REDIRECT_URL = configData.result.REDIRECT_URL;
    env_server.EXTEND_DURATION = Number.parseInt((configData.result.EXTEND_DURATION).toString());
    env_server.PATIENT_ASSISSTANCE_MEETING_URL = configData.result.PATIENT_ASSISSTANCE_MEETING_URL
  }

  console.log(env_server);

  return <Outlet />;
}
