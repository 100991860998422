import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

export default function Loading({ text }) {
  return (
    <Box style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "10px" }} >
      <CircularProgress size={60} thickness={5} sx={{
        "& .MuiCircularProgress-svg": {
          color: "#87c44b",
        }
      }} />
      <Typography fontSize={"18px"} fontWeight={600} color={"#4d4d4d"}>{text}</Typography>
    </Box>
  )
}
