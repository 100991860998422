import { IEnvServer } from "data/interface/env_server.interface";

export const env_client = {
  SERVER_API_URL: process.env.REACT_APP_SERVER_API_URL || null,
  BYPASS_KEY: process.env.REACT_APP_BYPASS_KEY,
  BYPASS_TOKEN: process.env.REACT_APP_BYPASS_TOKEN,
  BYPASS_TOKEN_2: process.env.REACT_APP_BYPASS_TOKEN_2,
  SESSION_EXPIRE: process.env.REACT_APP_SESSION_EXPIRE
}

export var env_server: IEnvServer = {
  SOURCE: null,
  BINAH_KEY: null,
  BINAH_DURATION: null,
  REDIRECT_URL: null,
  EXTEND_DURATION: null,
  PATIENT_ASSISSTANCE_MEETING_URL: null,
};

export const CONSTANT_VALUE = {
  vitalRPM: "VITALRPM",
  callFlow: "CALLFLOW",
  dev: "DEV",
  production: "PRODUCTION"
}

export const SOURCE_PARAM = {
  tokenParam: "token",
  iframeParam: "iframe",
  autostartParam: "autostart",
  filterParam: "filter"
}