import React from "react";
import ReactDOM from "react-dom/client";
import styled from "styled-components";
import { Flex } from "components/shared/Flex";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Measure from "pages/Measuring/Measure";
import Review from "pages/Review/Review";
import NotFound from "pages/Error/NotFound";
import SessionExpired from "pages/Error/SessionExpired";
import Unauthorized from "pages/Error/Unauthorized";
import ValidateRoute from "pages/Routes/ValidateRoute";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { store } from "./store/store";
import { Provider } from "react-redux";
import GetParamRoute from './pages/Routes/GetParamRoute';
import ValidateSourceConfig from "pages/Routes/GetSourceConfig";
import ReduxDataGlobal from "utils/global/reduxData.global";
import GetSourceConfig from "pages/Routes/GetSourceConfig";

const Wrapper = styled(Flex)`
  background-color: #f7f9ff;
  flex-direction: column;
  align-items: center;
  font-family: Rubik, Segoe UI, sans-serif;
  height: 100svh;
  min-height: 0px;
  max-height: 100svh;
  min-width: 0px;
  max-width: 100%;
  overflow: hidden;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Wrapper>
        <BrowserRouter>
          <ReduxDataGlobal />
          <Routes>

            <Route element={<GetSourceConfig />}>
              <Route element={<GetParamRoute />}>
                <Route path="vitalsign">
                  <Route element={<ValidateRoute />}>
                    <Route path="" element={<Measure />} />
                  </Route>
                  <Route path="review" element={<Review />} />
                </Route>
              </Route>
            </Route>

            <Route path="404" element={<NotFound />} />
            <Route path="401" element={<Unauthorized />} />
            <Route path="expired" element={<SessionExpired />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Wrapper>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </Provider>
  </QueryClientProvider>
);
