export const vitalRequireTime = {
  //https://binah.zendesk.com/hc/en-us/articles/360011448458-Accuracy-table-and-report-times#report-timings-0-2
  HR: 20,
  BR: 37,
  PRQ: 37,
  HRV: 50,
  SpO2: 35,
  BP: 35,
  PNS: 50,
  SL: 50,
  HG: 50,
  A1c: 50,
  WR: 20,
}