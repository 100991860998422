
import axios from 'axios';
import { env_client } from '../data/env/envKey.env';
import { reduxDataGlobal } from 'utils/global/reduxData.global';

const axiosInstance = axios.create({
  baseURL: env_client.SERVER_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// axiosInstance.interceptors.request.use(
//   (config) => {
//     config.headers['HostnameID'] = reduxDataGlobal.HostnameID;
//     return config
//   },
//   (err) => {
//     console.log(err)
//     // return Promise.reject(err);
//   },
// )

export default axiosInstance;