import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultState, setIsExpire, setIsExpireTime } from 'store/isExpireSession/isExpireSessionSlice';
import { changeIsStartState } from 'store/measureStatus/measureStatusSlice';
import { RootState } from 'store/store';

export const useSessionTimer = () => {
  const dispatch = useDispatch();
  const { startMeasure, isStarted } = useSelector((state: RootState) => state.measureStatus)
  let { expireTime, isExpire } = useSelector((state: RootState) => state.isExpire);

  useEffect(() => {
    let interval;
    if (isStarted && expireTime > 0) {
      interval = setInterval(() => {
        dispatch(setIsExpireTime());
      }, 1000);
    } else if (expireTime === 0) {
      dispatch(setIsExpire());
      dispatch(changeIsStartState(false))
    }
    return () => {
      clearInterval(interval);
    }
  }, [isStarted, expireTime]);

  const handleStart = () => {
    if (isExpire && expireTime <= 0) {
      dispatch(setDefaultState())
      dispatch(changeIsStartState(true))
    } else if (!isExpire && expireTime > 0) {
      dispatch(changeIsStartState(true))
    }
  };

  useEffect(() => {
    if (startMeasure) {
      if (!isStarted) {
        handleStart();
      }
    }
  }, [startMeasure])

  return expireTime;
}