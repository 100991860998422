import React, { useEffect, useRef, useState } from 'react'
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { Flex } from 'components/shared/Flex';
import Timer from "./Timer";
import BeatingRate from './BeatingRate';
import HeartRate from './HeartRate';
import TransitionText from './TransitionText';
import { GetVitalConstantData, Selection } from 'data/constant/vitalDescription';

const PopupWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: 13.5rem;
  @media (min-height: 1300px){
  -webkit-transform : scale(1.5);
  -moz-transform : scale(1.5);
  -o-transform : scale(1.5);
  -ms-transform : scale(1.5);
  transform : scale(1.5);
  -webkit-transform-origin : 50% 100%;
  -moz-transform-origin : 50% 100%;
  -o-transform-origin : 50% 100%;
  -ms-transform-origin : 50% 100%;
  transform-origin : 50% 100%;

  }
  @media (max-height: 1051px){
  -webkit-transform : scale(0.9);
  -moz-transform : scale(0.9);
  -o-transform : scale(0.9);
  -ms-transform : scale(0.9);
  transform : scale(0.9);
  -webkit-transform-origin : 50% 100%;
  -moz-transform-origin : 50% 100%;
  -o-transform-origin : 50% 100%;
  -ms-transform-origin : 50% 100%;
  transform-origin : 50% 100%;
  }
  @media (max-height: 769px){
  -webkit-transform : scale(0.85);
  -moz-transform : scale(0.85);
  -o-transform : scale(0.85);
  -ms-transform : scale(0.85);
  transform : scale(0.85);
  -webkit-transform-origin : 50% 100%;
  -moz-transform-origin : 50% 100%;
  -o-transform-origin : 50% 100%;
  -ms-transform-origin : 50% 100%;
  transform-origin : 50% 100%;
  }
  @media (max-height: 600px){
  -webkit-transform : scale(0.8);
  -moz-transform : scale(0.8);
  -o-transform : scale(0.8);
  -ms-transform : scale(0.8);
  transform : scale(0.8);
  -webkit-transform-origin : 50% 100%;
  -moz-transform-origin : 50% 100%;
  -o-transform-origin : 50% 100%;
  -ms-transform-origin : 50% 100%;
  transform-origin : 50% 100%;
  }
  @media (max-height: 420px){
  -webkit-transform : scale(0.75);
  -moz-transform : scale(0.75);
  -o-transform : scale(0.75);
  -ms-transform : scale(0.75);
  transform : scale(0.75);
  -webkit-transform-origin : 50% 100%;
  -moz-transform-origin : 50% 100%;
  -o-transform-origin : 50% 100%;
  -ms-transform-origin : 50% 100%;
  transform-origin : 50% 100%;
  }
  @media (max-height: 350px){
  -webkit-transform : scale(0.7);
  -moz-transform : scale(0.7);
  -o-transform : scale(0.7);
  -ms-transform : scale(0.7);
  transform : scale(0.7);
  -webkit-transform-origin : 50% 100%;
  -moz-transform-origin : 50% 100%;
  -o-transform-origin : 50% 100%;
  -ms-transform-origin : 50% 100%;
  transform-origin : 50% 100%;
  }

  //start - Support VA
  @media(max-width: 1160px){
    height: 5rem;
    top: 6.2vh;
    bottom: auto;
  }

  @media(max-width: 950px){
    height: 11rem;
    bottom: 0rem;
    top: auto;
  }
  //end - Support VA

  @media (max-width: 500px){
      height: 11rem;
      bottom: 0;
  }
  @media (max-width: 320px){
      height: 8.5rem;
      bottom: 0;
  }
  @media (max-width: 240px){
      height: 5.5rem;
      bottom: 0;
  }
`;

const Wrapper = styled(Flex)`
  height: fit-content;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 5px;
  background-color: rgba(255,255,255,1);
  border-radius: 20px; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
  flex-direction: column;
  @media (max-width: 500px){
    background-color: rgba(255,255,255,1);
    width: 94vw;
  }
  @media (max-width: 240px){
    background-color: rgba(255,255,255,1);
    border-radius: 10px;
  }
`;

const TopWrapper = styled(Flex)`
  height: 15%;
  justify-content: center;
  align-items: center;
  //start - Support VA
  @media (max-width: 1160px){
    height: 40%;
    margin-bottom: 5px;
  }
  @media(max-width: 950px){
    height: 15%;
    margin-bottom: 0px;
  }
  //end - Support VA
  @media (max-width: 500px){
    height: 15%;
    margin-bottom: 0px;
  }
`;

const MiddleWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px 20px;
  height: 60%;
  width: 90%;
  font-size: 18px;

  //start - Support VA
  @media (max-width: 1160px){
      display: none;
  }
  @media (max-width: 950px){
      display: contents;
  }
  //end - Support VA

  @media (max-width: 500px){
      display: contents;
      font-size: 14px;
  }
  @media (max-width: 320px){
      display: contents;
      padding: 0 2px;
      font-size: 9px;
  }
  @media (max-width: 240px){
      display: contents;
      font-size: 7px;
  }
`;

const BottomWrapper = styled(Flex)`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 15%;

  //start - Support VA
  @media (max-width: 1160px){
    height: 40%;
    width: 400px;
  }
  @media (max-width: 950px){
    height: 15%;
    width: 100%;
  }
  //end - Support VA

  @media (max-width: 500px){
    height: 15%;
    width: 100%;
   }
`;

const ValueWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 2px;
`;

const Value = styled(Flex)`
  line-height: 100%;
  font-weight: 700;
  color: #000;
  font-size: 2.7vh;
`;

const Unit = styled(Flex)`
  color: #000;
  height: 15%;
  line-height: 100%;
  font-size: 1.5vh;
  position: relative;
  bottom: 1px;
`;

export default function MeasurePopup({ isMeasuring, vitalSigns }: any) {
  const [checked, setChecked] = useState(isMeasuring);
  const [slideDirection, setSlideDirection] = useState(null);
  const containerRef = useRef(null);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    setChecked(isMeasuring);
  }, [isMeasuring])

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenSize.width <= 1160 && screenSize.width >= 950) {
      setSlideDirection("down");
    }
    else {
      setSlideDirection("up");
    }
  }, [screenSize])

  return (
    <PopupWrapper ref={containerRef}>
      <Slide appear={checked} direction={slideDirection} timeout={350} in={checked} container={containerRef.current} style={{ height: "100%" }}>
        <Wrapper>
          <TopWrapper>
            <BeatingRate />
            <ValueWrapper>
              <Value>{vitalSigns.heartRate.isEnabled ? vitalSigns.heartRate.value || "--" : "--"}</Value>
              <Unit>{GetVitalConstantData("HR", Selection.VitalUnit)}</Unit>
            </ValueWrapper>
          </TopWrapper>

          <MiddleWrapper>
            <TransitionText isMeasuring={isMeasuring} />
            <HeartRate />
          </MiddleWrapper>

          <BottomWrapper>
            <Timer started={isMeasuring} />
          </BottomWrapper>
        </Wrapper>
      </Slide>
    </PopupWrapper>
  )
}