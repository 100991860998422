import { createSlice } from '@reduxjs/toolkit'
import { SESSION } from 'data/constant/vitalConstraint';

export interface MeasureSessionState {
  session: SESSION
}

const initialState: MeasureSessionState = {
  session: SESSION.NONE,
}

export const measureSessionSlice = createSlice({
  name: "measureSession",
  initialState,
  reducers: {
    measureSessionInit: (state) => {
      state.session = SESSION.INIT;
    },
  }
})

export const { measureSessionInit } = measureSessionSlice.actions;

export default measureSessionSlice.reducer;