import { HealthMonitorCodes, SessionState } from "@binah/web-sdk";
import { useError, useMonitor, usePageVisibility, usePrevious, useWarning } from "hooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorAlert, InfoAlert, WarningAlert } from "components/Alert";
import { VideoReadyState } from "types";
import media from "utils/style/media";
import { mirror } from "utils/style/mirror";
import styled from "styled-components";
import { Flex } from "../shared/Flex";
import Mask from "../../assets/primas/primas-mask-9.svg";
import TopBar from "./TopBar";
import MeasurePopup from "components/VitalMeasurePopup/MeasurePopup";
import Loader from "./Loader";
import ToggleIframe from "./ToggleIframe";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { setDefaultState, setIsExpire, setRenew, setIsExpireTime } from "store/isExpireSession/isExpireSessionSlice";
import { changeFinishMeasureState, changeIsStartState, changeStartMeasureState } from "store/measureStatus/measureStatusSlice";
import { SESSION } from "data/constant/vitalConstraint";
import { changeAutoStartEnableState } from "store/paramData/paramDataSlice";
import Review from "pages/Review/Review";
import { setIsReviewHidden } from "store/popupStateManagement/popupStateManagementSlice";


const MonitorWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: center;
  flex: 1;
  overflow: hidden;
  ${media.tablet`
    width: fit-content;
    justify-content: center;
  `}
`;

const MeasurementContentWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  flex: 7.55;
  height: 90%;
`;

const VideoAndStatsWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: inherit;
  z-index: -1;
`;

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  ${media.desktop`
  `}
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${media.desktop`
  `}
  ${mirror}
`;

export default function PrimasMonitor({
  cameraId,
}) {
  const video = useRef<HTMLVideoElement>(null);
  const [startMeasuring, setStartMeasuring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [session, setSession] = useState<boolean>(true);
  const [loadingTimeoutPromise, setLoadingTimeoutPromise] = useState<number>();
  const isPageVisible = usePageVisibility();
  const { sessionState, vitalSigns, offlineMeasurements, error, warning, info } = useMonitor(video, cameraId, null, startMeasuring);
  const prevSessionState = usePrevious(sessionState);
  const errorMessage = useError(error);
  const warningMessage = useWarning(warning);
  const isMeasuring = useCallback(() => sessionState === SessionState.MEASURING, [sessionState]);
  const isVideoReady = useCallback(() => video.current?.readyState === VideoReadyState.HAVE_ENOUGH_DATA, []);

  const dispatch = useDispatch();
  const { isReviewHidden } = useSelector((state: RootState) => state.popupStateManagement);
  const { isUploadPDF } = useSelector((state: RootState) => state.uploadFile);
  const processingTime = parseInt(useSelector((state: RootState) => state.keyDurationSession.processingTime));
  const { startMeasure } = useSelector((state: RootState) => state.measureStatus)
  const { autostart } = useSelector((state: RootState) => state.paramData);
  let { isExpire } = useSelector((state: RootState) => state.isExpire);
  const measureSession = useSelector((state: RootState) => state.measureSession.session);

  const handleButtonClick = useCallback(() => {
    // if (SOURCE_FLAG.sourceFlag == FLAG_VALUE.callFlow) {
    //   //if testmode == true
    //   if (localStorage.getItem(process.env.REACT_APP_BYPASS_CHECKING_KEY) == process.env.REACT_APP_BYPASS_CHECKING_VALUE) {
    //     async function callAPI() {
    //       const dataSending: {} = {
    //         ID: `3;${localStorage.getItem(process.env.REACT_APP_BYPASS_KEY)}`,
    //         KeyPair: Helper.ModifiedRequestData(Helper.randomVitalSignData),
    //       };
    //       const res = await axios.post(
    //         `${API_PATH.addKeyPairValue}`,
    //         dataSending
    //       );
    //       return res;
    //     }

    //     try {
    //       callAPI();
    //       localStorage.setItem("refresh", "0");
    //       navigate(`/vitalsign/review?token=${token}`, { state: Helper.randomVitalSignData });
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   };

    dispatch(changeFinishMeasureState(false));
    dispatch(setIsReviewHidden(true));

    setIsLoading(true);
    if (sessionState === SessionState.ACTIVE) {
      setStartMeasuring(true);
      if (isExpire) {
        dispatch(setRenew());
      }
      setLoadingTimeoutPromise(
        window.setTimeout(() => setIsLoading(true), processingTime * 1000)
      );
    } else if (isMeasuring()) {
      clearTimeout(loadingTimeoutPromise);
      setStartMeasuring(false);
    }
  }, [setIsLoading, processingTime, session, sessionState, isExpire]);

  useEffect(() => {
    if (isMeasuring()) {
      setIsLoading(false);
      !isPageVisible && setStartMeasuring(false);
    }
    //catch event when the measure finish (the state change)
    if (sessionState === SessionState.ACTIVE && prevSessionState !== sessionState) {
      setStartMeasuring(false);
      setIsLoading(false);
    }
  }, [errorMessage, sessionState, isPageVisible]);

  useEffect(() => {
    //autostart logic
    if (autostart && measureSession == SESSION.INIT) {
      dispatch(changeAutoStartEnableState(false));
      handleButtonClick();
    }
  }, [measureSession, sessionState]);

  useEffect(() => {
    //When there is any of the button start clicked
    if (startMeasure) {
      handleButtonClick();
      dispatch(changeStartMeasureState(false));
    }
  }, [startMeasure])

  return (
    <>
      <TopBar
        isMeasuring={isMeasuring()}
        isLoading={isLoading}
        isVideoReady={isVideoReady()}
        isShown={true}
      />
      <MonitorWrapper>
        <MeasurementContentWrapper>
          <VideoAndStatsWrapper>
            <VideoWrapper>
              <Img src={Mask} />
              <Video ref={video} id="video" muted={true} playsInline={true} />
            </VideoWrapper>
            {isMeasuring() && <InfoAlert message={info.message} />}
            {warningMessage != "" && <WarningAlert message={warningMessage} />}
            {errorMessage != "" && <ErrorAlert message={errorMessage} />}
            <MeasurePopup
              isMeasuring={isMeasuring()}
              vitalSigns={vitalSigns}
            />
          </VideoAndStatsWrapper>
          {!isVideoReady() && <Loader opacity={1} />}
        </MeasurementContentWrapper>
      </MonitorWrapper>

      <ToggleIframe isHidden={isReviewHidden || isUploadPDF}>
        {" "}
        <Review />
      </ToggleIframe>
    </>
  );
}
