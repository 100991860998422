import { UNKNOWN_DATA, VITALSIGN_LOGIC, VitalElementArray } from "data/constant/vitalConstraint";
import { env_server, CONSTANT_VALUE } from "data/env/envKey.env";

const ModifiedRequestData = (dataObj: any) => {
  var results: string = "";

  if (env_server.SOURCE == CONSTANT_VALUE.callFlow) {
    results = results.concat(`StartMeasure;true;`);
  }

  for (const [key, value] of Object.entries(dataObj)) {
    results = results.concat(`${key};${value};`);
  }

  //VITALRPM
  if (env_server.SOURCE == CONSTANT_VALUE.vitalRPM) {
    results = results.slice(0, -1);
  }
  //CALLFLOW
  else if (env_server.SOURCE == CONSTANT_VALUE.callFlow) {
    results = results.concat(`Completed;true`);
  }

  return results;
}

function GetRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  const data = Math.floor(Math.random() * (max - min) + min);
  return data // The maximum is exclusive and the minimum is inclusive
}

function GetRandomFloat(min: number, max: number, fixed: number) {
  const data = (Math.random() * (max - min) + min).toFixed(fixed);
  return data; // The maximum is exclusive and the minimum is inclusive
}

const UpperFirstLetter = (data: string) => {
  if (data) {
    return data[0].toUpperCase() + data.substring(1);
  }
}

const ConvertJsonToLowercase = (obj: any): any => {
  var key: any, keys = Object.keys(obj), n = keys.length, newObj = {};
  while (n--) {
    key = keys[n];
    newObj[key.toLowerCase()] = obj[key];
  }
  return newObj;
}

const DisplayGender = (data: string): string => {
  switch (data) {
    case "0": {
      return "Other"
    }
    case "1": {
      return "Male"
    }
    case "2": {
      return "Female"
    }
    default: {
      return Helper.UpperFirstLetter(data)
    }
  }
}

const DisplayPregnant = (data: string): string => {
  return data == "1" ? "Yes" : "No";
}

const ResolveDataKeyValue = (data: any): any => {
  const dataParse = JSON.parse(data);
  return Object.values(dataParse[0])[1];
};

const GetRandomVitalsign = (vitalArray: string[]) => {
  var returnData = {
    StartMeasure: true,
  }

  const getRandom = (vitalSign: string) => {
    if (vitalSign === "WR") {
      return `${Helper.GetRandomInt(1, 10)}`
    }
    else if (vitalSign === "HR") {
      return `${Helper.GetRandomInt(50, 120)}`
    }
    else if (vitalSign === "BR") {
      return `${Helper.GetRandomInt(11, 36)}`;
    }
    else if (vitalSign === "SL") {
      return `${Helper.GetRandomInt(1, 5)}`;
    }
    else if (vitalSign === "BP") {
      return `${Helper.GetRandomInt(110, 130)}/${Helper.GetRandomInt(70, 90)}`;
    }
    else if (vitalSign === "PNS") {
      return `${Helper.GetRandomInt(1, 5)}`;
    }
    else if (vitalSign === "HRV") {
      return `${Helper.GetRandomInt(40, 110)}`;
    }
    else if (vitalSign === "HG") {
      return `${Helper.GetRandomFloat(6, 20, 1)}`;
    }
    else if (vitalSign === "A1c") {
      return `${Helper.GetRandomFloat(1, 8, 2)}`;
    }
    else if (vitalSign === UNKNOWN_DATA.NaN) {
      return UNKNOWN_DATA.NaN;
    } else {
      return UNKNOWN_DATA.NaN;
    }
  }

  for (var vital of VitalElementArray) {
    if (vitalArray.includes(vital)) {
      returnData[vital] = getRandom(vital);
    } else {
      returnData[vital] = getRandom(UNKNOWN_DATA.NaN);
    }
  }

  returnData[VITALSIGN_LOGIC.Completed] = true;
  return returnData;
}

const FormatTimeFromMinutes = (minutes: number) => {
  if (minutes >= 60) {
    const date = new Date();
    date.setHours(0, minutes, 0, 0);
    const formattedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    return formattedTime;
  } else {
    return minutes;
  }
};

export var Helper: {
  ModifiedRequestData: Function,
  GetRandomInt: Function,
  GetRandomFloat: Function,
  UpperFirstLetter: Function,
  ConvertJsonToLowercase: Function,
  DisplayGender: Function,
  DisplayPregnant: Function,
  ResolveDataKeyValue: Function,
  GetRandomVitalsign: Function,
  FormatTimeFromMinutes: Function,
} = {
  ModifiedRequestData,
  GetRandomInt,
  GetRandomFloat,
  UpperFirstLetter,
  ConvertJsonToLowercase,
  DisplayGender,
  DisplayPregnant,
  ResolveDataKeyValue,
  GetRandomVitalsign,
  FormatTimeFromMinutes,
};