import { Flex } from "components/shared/Flex";
import React from "react";
import styled from "styled-components";
import red_flag from "../../assets/icon/review/red-flag.svg";

const IsabelResultBox = styled(Flex)`
  font-size: 1.25rem;
  column-gap: 0.3rem;
  justify-content: start;
  align-items: center;
  @media (max-width: 1350px) {
    font-size: 1.15rem;
  }
  @media (max-width: 1100px) {
    font-size: 1.1rem;
  }
  @media (max-width: 950px) {
    font-size: 1rem;
  }
  @media (max-width: 700px) {
    font-size: 0.9rem;
  }
  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
  @media (max-width: 360px) {
    font-size: 0.65rem;
  }
`;

const ConditionLink = styled.a`
  text-decoration: none;
  color: #646c7e;
  font-weight: 700;
`;

const Flag = styled.img`
  width: 1.1rem;
  height: 1.1rem;

  @media (max-width: 480px) {
    width: 0.75rem;
    height: 0.75rem;
  }

  @media (max-width: 320px) {
    width: 0.6rem;
    height: 0.6rem;
  }

  @media (max-width: 240px) {
    width: 0.35rem;
    height: 0.35rem;
  }
`;

const Common = styled.span`
  //level
  border-radius: 50px;
  padding: 0.125rem 0.4rem;
  color: white;
  font-size: 0.85rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  //common
  background-color: #c1fae2;
  color: #00d681;
  position: relative;
  font-weight: 600;

  @media (max-width: 480px) {
    border-radius: 0.5rem;
    height: 0.75rem;
    font-size: 0.75rem;
    padding: 0.125rem 0.3rem;
  }
  @media (max-width: 320px) {
    border-radius: 0.5rem;
    height: 0.6rem;
    font-size: 0.6rem;
    padding: 0.2rem 0.3rem;
  }
  @media (max-width: 240px) {
    border-radius: 0.5rem;
    height: 0.35rem;
    font-size: 0.35rem;
    padding: 0.125rem 0.2rem;
  }
`;

const ConditionWrapper = styled.div`
  width: auto;
  word-break: break-all;
`;

export default function IsabelResult({ condition }: any) {
  const openNewWindow = (e) => {
    e.preventDefault();
    window.open(
      `https://vsearch.nlm.nih.gov/vivisimo/cgi-bin/query-meta?v%3Aproject=medlineplus&query=${condition.name}`,
      "_blank",
      "location=yes,scrollbars=yes,status=yes"
    );
  };
  return (
    <IsabelResultBox>
      <ConditionWrapper>
        <ConditionLink onClick={openNewWindow} href="#">
          {condition.name}
        </ConditionLink>
      </ConditionWrapper>
      {condition.red_flag == "true" && <Flag src={red_flag}></Flag>}
      {condition.common == "true" && <Common>C</Common>}
    </IsabelResultBox>
  );
}
