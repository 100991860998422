import React from 'react'
import './Error.css'

export default function error() {
  return (
    <>
      <div id="error">
        <div className="error">
          <div className="error-404">
            <div></div>
            <h1>401</h1>
          </div>
          <h2>Unauthorized</h2>
          <p>You tried to access a page you did not have prior authorization for</p>
          {/* <a href="https://www.primas.net/">Contact</a> */}
        </div>
      </div>
    </>
  )
}
