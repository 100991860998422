import { useState, useEffect } from 'react';

const useTimer = (started, durationSeconds) => {
  const [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState<any>(0);

  useEffect(() => {
    if (started) {
      setSeconds(1);
      const intervalId = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
      setIntervalId(intervalId);
      return () => clearInterval(intervalId);
    } else {
      clearInterval(intervalId);
    }
  }, [started]);

  useEffect(() => {
    if (seconds === durationSeconds) {
      clearInterval(intervalId);
    }
  }, [seconds]);

  return seconds;
};
export default useTimer;
