import { Flex } from "components/shared/Flex";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactSpeedometer from "react-d3-speedometer";
import { Transition } from "react-d3-speedometer";
import { Helper } from "utils/helper/helper.helper";

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  position: relative;
`;

const Border = styled(Flex)`
  position: relative;
  width: 12rem;
  height: 12rem;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(
    circle,
    #e8edf7,
    #eff1f9,
    #f5f6fb,
    #fafafd,
    #ffffff
  );
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;

  @media (max-width: 1400px) {
    width: 10rem;
    height: 10rem;
  }
  @media (max-width: 1135px) {
    width: 9rem;
    height: 9rem;
  }
  @media (max-width: 1050px) {
    width: 8.5rem;
    height: 8.5rem;
  }
  @media (max-width: 870px) {
    width: 7.5rem;
    height: 7.5rem;
  }
  @media (max-width: 700px) {
    width: 9rem;
    height: 9rem;
  }
  @media (max-height: 400px) {
    width: 6.5rem;
    height: 6.5rem;
  }
  @media (max-width: 360px) {
    width: 7.5rem;
    height: 7.5rem;
  }

  /* @media (max-width: 240px){
        width: 4.5rem;
        height: 4.5rem;
    } */
`;

const Instructor = styled(Flex)`
  height: 2.5rem;
  width: 7rem;
  bottom: 12px;
  position: absolute;
  font-size: 0.6rem;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 1050px) {
    font-size: 0.5rem;
    width: 6rem;
    bottom: 10px;
    line-height: 0.5rem;
  }
  @media (max-width: 700px) {
    font-size: 0.6rem;
    height: 2.5rem;
    width: 7rem;
    bottom: 12px;
  }
  @media (max-width: 480px) {
    font-size: 0.4rem;
    height: 2rem;
    width: 4.5rem;
    bottom: 13px;
  }
`;

export default function ProgressBar({
  wellnessRange,
  wellnessRangeStatus,
}: any) {
  const [width, setWidth] = useState(180);
  const [height, setHeight] = useState(180);
  const [needle, setNeedle] = useState(0.6);
  const [ringWidth, setRingWidth] = useState(13);
  const [textPadding, setTextPadding] = useState(9);
  const [fontSize, setFontsize] = useState(1.063);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    if (screenSize.width >= 1050 && screenSize.width < 1135) {
      setWidth(170);
      setHeight(170);
      setNeedle(0.6);
      setRingWidth(13);
      setFontsize(1);
      setTextPadding(5);
    } else if (screenSize.width >= 870 && screenSize.width < 1050) {
      setWidth(160);
      setHeight(160);
      setNeedle(0.5);
      setFontsize(0.7);
      setTextPadding(1);
      setRingWidth(9);
    } else if (screenSize.width > 700 && screenSize.width < 870) {
      setWidth(140);
      setHeight(140);
      setNeedle(0.4);
      setFontsize(0.5);
      setTextPadding(-10);
      setRingWidth(6);
    } else if (screenSize.width > 360 && screenSize.width <= 700) {
      setWidth(170);
      setHeight(170);
      setNeedle(0.6);
      setFontsize(1.063);
      setTextPadding(9);
      setRingWidth(13);
    } else if (screenSize.width <= 360) {
      setWidth(130);
      setHeight(130);
      setNeedle(0.45);
      setFontsize(1.063);
      setTextPadding(-5);
      setRingWidth(13);
    }
    if (screenSize.height < 400) {
      setWidth(130);
      setHeight(130);
      setNeedle(0.4);
      setFontsize(0.5);
      setTextPadding(-12);
      setRingWidth(5);
    }
  }, [screenSize]);

  return (
    <Wrapper>
      <Border>
        <ReactSpeedometer
          value={wellnessRange ? Number.parseInt(wellnessRange) : 0}
          minValue={0}
          maxValue={10}
          forceRender={true}
          width={width}
          height={height}
          ringWidth={ringWidth}
          segments={500}
          maxSegmentLabels={0}
          needleTransition={Transition.easeCubicInOut}
          needleTransitionDuration={500}
          currentValueText={`${Helper.UpperFirstLetter(wellnessRangeStatus)}`}
          valueTextFontSize={`${fontSize}rem`}
          labelFontSize={"0.688rem"}
          paddingVertical={textPadding}
          textColor={"#000"}
          needleHeightRatio={needle}
          needleColor={"#55545b"}
          startColor={"#e61e0b"}
          endColor={"#89e935"}
        />
      </Border>
      <Instructor>{`The measured indicators are not intended for medical use`}</Instructor>
    </Wrapper>
  );
}
