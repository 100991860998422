import { env_client } from 'data/env/envKey.env';
import { API_PATH } from "data/api/APISetting";
import axiosInstance from "./axiosInstance.service";

const uploadFile = async (file: Blob, fileName: string) => {
  const formData = new FormData();
  formData.append('file', file, fileName);
  const response = await axiosInstance.post(`${API_PATH.uploadFile}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data;
}

const downloadFile = async (fileName: string) => {
  const link = document.createElement('a');
  console.log(`${API_PATH.downloadFile}/${fileName}`)
  link.href = `${API_PATH.downloadFile}/${fileName}`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export const uploadService = {
  uploadFile,
  downloadFile
}