import React from 'react'
import styled, { keyframes } from 'styled-components';

const Heart = styled.div`
  top: -3px;
  position: relative;
  width: 3rem;
  height: 3rem;

  @media (max-width: 240px){
    width: 1.8rem;
  }

  &:before,
  &:after {
    position: absolute;
    content: '';

    left: 50px;
    top: 0;
    width: 50px;
    height: 80px;

    @media (max-width: 240px){
      left: 33px;
      top: 10px;
      width: 34px;
      height: 50px;
    }

    background: red;
    border-radius: 50px 50px 0 0;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
  }
  &:after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
  }
`;

const heartBeat = keyframes`
  0%
  {
    transform: scale( 0.2 );
  }
  50%
  {
    transform: scale( 0.25 );
  }
  100%
  {
    transform: scale( 0.2 );
  }
`;

const AnimatedHeart = styled(Heart)`
  animation: ${heartBeat} 0.5s infinite;
`;

export default function BeatingRate() {

  return (
    <AnimatedHeart />
  )
}
