import { Flex } from "components/shared/Flex";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import sad from "../../assets/icon/result/sad-face.svg";
import happy from "../../assets/icon/result/happy-face.svg";
import { GetVitalConstantData, Selection } from "data/constant/vitalDescription";
import { STATUS_KEY } from "data/constant/vitalConstraint";

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  cursor: pointer;
`;

const ChildWrapper = styled(Flex)`
  width: 100%;
  height: 45%;
  justify-content: center;
`;

const IconBorderWrapper = styled(Flex)`
  border-radius: 50px;
  height: 1.6rem;
  width: 1.6rem;
  justify-content: center;
  align-items: center;

  @media (max-width: 480px) {
    height: 1.4rem;
    width: 1.4rem;
  }

  @media (max-width: 360px) {
    height: 1.3rem;
    width: 1.3rem;
  }

  @media (max-width: 320px) {
    height: 1.1rem;
    width: 1.1rem;
  }

  @media (max-width: 240px) {
    height: 0.6rem;
    width: 0.6rem;
  }
`;

const IconImg = styled.img`
  height: 100%;
  box-sizing: border-box;
  margin-right: 0.5rem;
  width: auto;
  position: relative;
  @media (max-width: 1050px) {
    height: 80%;
  }
`;

const CardIconImg = styled.img`
  height: 4.4rem;

  @media (max-width: 1135px) {
    height: 3.4rem;
  }
  @media (max-width: 1050px) {
    height: 3rem;
  }
  @media (max-width: 1050px) {
    height: 2.5rem;
  }
  @media (max-width: 360px) {
    height: 2rem;
  }
`;

const IconWrapper = styled(Flex)`
  width: 50%;
  justify-content: center;
  align-items: center;
  @media (max-width: 1220px) {
    width: 40%;
  }
  @media (max-width: 1050px) {
    width: 30%;
  }
`;

const TitleWrapper = styled(Flex)`
  height: 30%;
  width: 100%;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  gap: 1rem;
  @media (max-width: 1135px) {
    font-size: 1rem;
  }
  @media (max-width: 1050px) {
    font-size: 0.8rem;
    gap: 0;
  }
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const ResultWrapper = styled(Flex)`
  width: 50%;
  flex-direction: row;
`;

const ResultText = styled(Flex)`
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  color: #646c7e;
  font-weight: 700;
`;

const Result = styled(Flex)`
  align-items: flex-end;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: 600;

  @media (max-width: 1135px) {
    font-size: 2rem;
  }
  @media (max-width: 1050px) {
    font-size: 1.7rem;
  }
  @media (max-width: 870px) {
    font-size: 1.4rem;
  }
`;

const BPResult = styled(Flex)`
  align-items: flex-end;
  justify-content: center;
  font-size: 2.3rem;
  font-weight: 600;
  @media (max-width: 1135px) {
    font-size: 1.8rem;
  }
  @media (max-width: 1050px) {
    font-size: 1.5rem;
  }
  @media (max-width: 870px) {
    font-size: 1.2rem;
  }
`;

const Unit = styled(Flex)`
  font-size: 0.9rem;
  align-self: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1135px) {
    font-size: 0.7rem;
  }
  @media (max-width: 1050px) {
    font-size: 0.6rem;
  }
  @media (max-width: 870px) {
    font-size: 0.5rem;
  }
`;

const RangeWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-style: oblique;
  width: 100%;
  height: 25%;
  color: #646c7e;
  @media (max-width: 1135px) {
    font-size: 13px;
  }
  @media (max-width: 1050px) {
    font-size: 11px;
  }
  @media (max-width: 870px) {
    font-size: 10px;
  }
`;

const Range = styled(Flex)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default function CardResult({
  vitalSign,
  status,
  resultTitle,
  result,
  unit,
}) {
  const [icon, setIcon] = useState();

  useEffect(() => {
    setIcon(GetVitalConstantData(vitalSign, Selection.VitalIcon));
  }, [vitalSign]);

  return (
    <>
      <Wrapper>
        <TitleWrapper>
          {icon ? (
            <IconBorderWrapper>
              <IconImg src={icon} />
            </IconBorderWrapper>
          ) : (
            <></>
          )}
          <Text>{resultTitle}</Text>
        </TitleWrapper>

        <ChildWrapper>
          <ResultWrapper>
            <ResultText>
              {resultTitle == "Blood Pressure" ? (
                <BPResult>{result}</BPResult>
              ) : (
                <Result>{result}</Result>
              )}
              {unit ? <Unit>{unit}</Unit> : <Unit>{status.toUpperCase()}</Unit>}
            </ResultText>
          </ResultWrapper>
          <IconWrapper>
            {(status === STATUS_KEY.NORMAL || status === STATUS_KEY.MEDIUM) && (
              <CardIconImg src={happy} />
            )}
            {(status === STATUS_KEY.HIGH || status === STATUS_KEY.LOW) && (
              <CardIconImg src={sad} />
            )}
          </IconWrapper>
        </ChildWrapper>

        <RangeWrapper>
          {GetVitalConstantData(vitalSign, Selection.VitalRangeDescription) ? (
            <Range>{GetVitalConstantData(vitalSign, Selection.VitalRangeDescription)}</Range>
          ) : (
            <Range>{"--"}</Range>
          )}
        </RangeWrapper>
      </Wrapper>
    </>
  );
}
