import { API_PATH } from 'data/api/APISetting';
import axiosInstance from './axiosInstance.service';
import { useQuery } from "react-query";
import { Helper } from 'utils/helper/helper.helper';
import { Encrypter } from 'utils/helper/cipher.helper';
import { ReturnResult } from 'data/model/returnResult.model';
import { setDurationSession, setKeySession } from 'store/keyDurationSession/keyDurationSessionSlice';
import { env_server } from 'data/env/envKey.env';
import { vitalRequireTime } from 'data/constant/vitalMeasureTime';

const getSettingByKey = async (param: string): Promise<ReturnResult<string>> => {
  const response = await axiosInstance.get(`${API_PATH.getSettingByKey}/${param}`);
  return response.data;
}

const useGetSettingByKey = (key: string, dispatch: any, filter?: any) => {
  return useQuery<string, Error>({
    queryKey: ['settingValue', `${key}`],
    queryFn: async () => {
      const response: ReturnResult<string> = await getSettingByKey(key);
      const encryptValue = Helper.ResolveDataKeyValue(response.result);
      return Encrypter.decrypt(encryptValue);
    },
    onSuccess(data) {
      if (key == env_server.BINAH_KEY) {
        const license = data;
        dispatch(setKeySession({ license }))
      }
      if (key == env_server.BINAH_DURATION) {
        const processTime = data;
        var processingTime: number;
        //If the filter param has
        if (filter.length != 0) {
          var maxTime: number = 0;
          for (var i = 0, length = filter.length; i < length; ++i) {
            if (vitalRequireTime[filter[i]] > maxTime) {
              maxTime = vitalRequireTime[filter[i]];
            }
          }
          processingTime = maxTime + env_server.EXTEND_DURATION;
        } else {
          processingTime = Number.parseInt(processTime);
        }
        dispatch(setDurationSession({ processingTime }))
      }
    }
  })
}

const getKeyPairValue = async (param: string): Promise<ReturnResult<string>> => {
  const response = await axiosInstance.get(`${API_PATH.getKeyPairValue}/${param}`);
  return response.data;
}

const useGetKeyPairValue = (key: string) => {
  return useQuery<ReturnResult<string>, Error>({
    queryKey: ['keyPairValue', `${key}`],
    queryFn: async () => {
      const data: ReturnResult<string> = await getKeyPairValue(key);
      return data;
    },
  })
}

const useGetKeyPairValueForValidation = (key: string) => {
  return useQuery<ReturnResult<string>, Error>({
    queryKey: ['keyPairValue', 'validation', `${key}`],
    queryFn: async () => {
      const data: ReturnResult<string> = await getKeyPairValue(key);
      return data;
    },
  })
}


const addKeyPairValue = async (data: {}): Promise<ReturnResult<string>> => {
  const response = await axiosInstance.post(`${API_PATH.addKeyPairValue}`, data);
  return response.data;
}

export const measureService = {
  getSettingByKey,
  addKeyPairValue,
  getKeyPairValue,
  useGetKeyPairValue,
  useGetSettingByKey,
  useGetKeyPairValueForValidation,
};