export const rangeCheckingVitalSign = (vitalSignKey: string, data: any, age?: number) => {
  //If data is NaN return NaN
  if (data == UNKNOWN_DATA.NaN) {
    return STATUS_KEY.UNKNOWN;
  }

  if (vitalSignKey === VITALSIGN_KEY.Stress) {
    data = Number.parseInt(data);
    // if (data < 2) {
    //     return STATUS_KEY.LOW;
    // } else
    if (data > 3) {
      return STATUS_KEY.HIGH;
    } else {
      return STATUS_KEY.NORMAL;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.HeartRate) {
    data = Number.parseInt(data);
    var lower_threshold: number = 60;
    var upper_threshold: number = 100;

    // if (age >= 1 && age <= 2) {
    //     lower_threshold = 80;
    //     upper_threshold = 130;
    // } else if (age >= 3 && age <= 4) {
    //     lower_threshold = 80;
    //     upper_threshold = 120;
    // } else if (age >= 5 && age <= 6) {
    //     lower_threshold = 75;
    //     upper_threshold = 115;
    // } else if (age >= 7 && age <= 9) {
    //     lower_threshold = 70;
    //     upper_threshold = 110;
    // } else if (age >= 10) {
    //     lower_threshold = 60;
    //     upper_threshold = 100;
    // }

    if (data < lower_threshold) {
      return STATUS_KEY.LOW;
    } else if (data > upper_threshold) {
      return STATUS_KEY.HIGH;
    } else {
      return STATUS_KEY.NORMAL;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.BreathingRate) {
    data = Number.parseInt(data);
    var lower_threshold: number = 12;
    var upper_threshold: number = 20;

    // if (age >= 1 && age <= 2) {
    //     lower_threshold = 25;
    //     upper_threshold = 35;
    // } else if (age >= 3 && age <= 4) {
    //     lower_threshold = 25;
    //     upper_threshold = 30;
    // } else if (age >= 5 && age <= 6) {
    //     lower_threshold = 20;
    //     upper_threshold = 25;
    // } else if (age >= 7 && age <= 9) {
    //     lower_threshold = 20;
    //     upper_threshold = 25;
    // } else if (age >= 10) {
    //     lower_threshold = 12;
    //     upper_threshold = 20;
    // }

    if (data < lower_threshold) {
      return STATUS_KEY.LOW;
    } else if (data > upper_threshold) {
      return STATUS_KEY.HIGH;
    } else {
      return STATUS_KEY.NORMAL;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.HRVariability) {
    data = Number.parseInt(data);
    if (data < 55) {
      return STATUS_KEY.LOW;
    } else if (data >= 55 && data <= 105) {
      return STATUS_KEY.NORMAL;
    } else {
      return STATUS_KEY.HIGH;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.Parasympathetic_Activity) {
    data = Number.parseFloat(data);
    if (data <= 1) {
      return STATUS_KEY.LOW;
    } else if (data >= 3) {
      return STATUS_KEY.HIGH;
    } else {
      return STATUS_KEY.NORMAL;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.BloodPressure) {
    data = data.toString();
    var systolic = Number.parseInt(`${data.split("/").shift()}`);
    var diastolic = Number.parseInt(`${data.split("/").pop()}`);
    if (systolic < 90 || diastolic < 60) {
      return STATUS_KEY.LOW;
    } else if ((systolic >= 90 && systolic <= 120) && (diastolic >= 60 && diastolic <= 80)) {
      return STATUS_KEY.NORMAL;
    } else if (systolic > 120 || diastolic > 80) {
      return STATUS_KEY.HIGH;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.WellnessRange) {
    if (data >= 1 && data <= 3) {
      return STATUS_KEY.LOW;
    } if (data >= 4 && data <= 7) {
      return STATUS_KEY.MEDIUM;
    } if (data >= 8 && data <= 10) {
      return STATUS_KEY.HIGH;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.Hemoglobin) {
    data = Number.parseFloat(data);
    if (data < 9) {
      return STATUS_KEY.LOW;
    } if (data >= 9 && data <= 17) {
      return STATUS_KEY.NORMAL;
    } if (data > 17) {
      return STATUS_KEY.HIGH;
    }
  } else if (vitalSignKey === VITALSIGN_KEY.HemoglobinA1c) {
    data = Number.parseFloat(data);
    if (data < 3) {
      return STATUS_KEY.LOW;
    } if (data >= 3 && data <= 6) {
      return STATUS_KEY.NORMAL;
    } if (data > 6) {
      return STATUS_KEY.HIGH;
    }
  }
  return STATUS_KEY.UNKNOWN;
}

export enum VITALSIGN_KEY {
  HeartRate = "HR",
  BreathingRate = "BR",
  BloodPressure = "BP",
  HRVariability = "HRV",
  Parasympathetic_Activity = "PNS",
  Stress = "SL",
  Pulse_Respiration_Quotient = "PRQ",
  Oxygen_Saturation = "Sp02",
  WellnessRange = "WR",
  Hemoglobin = "HG",
  HemoglobinA1c = "A1c"
}

export enum STATUS_KEY {
  NORMAL = "normal",
  HIGH = "high",
  LOW = "low",
  MEDIUM = "medium",
  UNKNOWN = "unknown"
}

export enum COLOR_KEY {
  BACKGROUND_RED = "#ffa4a5",
  BACKGROUND_GREEN = "#82ffc9",
  BACKGROUND_UNKNOWN = "#f3f3f3",

  MODAL_BACKGROUND_RED = "#ffe6e6",
  MODAL_BACKGROUND_GREEN = "#d4ffec",

  MODAL_WORD_GREEN = "#00ff62",
  MODAL_WORD_RED = "#ff4949",
  MODAL_WORD_UNKNOWN = "#000000",
}

export enum UNKNOWN_DATA {
  NaN = "NaN"
}

export enum SESSION {
  NONE = "NONE",
  INIT = "INIT",
  ACTIVE = "ACTIVE",
  MEASURING = "MEASURING",
  STOPPING = "STOPPING",
  TERMINATED = "TERMINATED"
}

export enum PROFILE_DATA {
  Pregnant = "pregnant",
  Age = "age",
  Gender = "gender",
  Weight = "weight",
  Symptoms = "symptoms"
}

export enum ISABEL_DATA {
  Symtoms = "symtoms",
  Condition = "condition",
}

export enum VITALSIGN_LOGIC {
  StartMeasure = "StartMeasure",
  Completed = "Completed",
}

export enum VITALSIGN_DATE {
  MeasureDate = "MeasureDate",
}

export const VitalElementArray: string[] = ["HR", "BR", "BP", "HRV", "PNS", "SL", "HG", "A1c", "WR"];

export var VitalBypassKey: string[] = [
  process.env.REACT_APP_BYPASS_TOKEN,
  process.env.REACT_APP_BYPASS_TOKEN_2
];