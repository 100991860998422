import { createSlice } from "@reduxjs/toolkit";
import { env_client } from "../../data/env/envKey.env";
export interface isExpireState {
  isExpire: boolean;
  expireTime: number;
  session: boolean;
}
const expireTime: number = Number(env_client.SESSION_EXPIRE) || 540;
const initialState: isExpireState = {
  isExpire: false,
  expireTime: expireTime,
  session: true,
};

export const isExpireSession = createSlice({
  name: "isExpire",
  initialState,
  reducers: {
    setDefaultState: (state) => {
      // console.log("setDefaultState")
      return {
        ...state,
        isExpire: false,
        expireTime: expireTime,
        session: true,
      };
    },
    setIsExpire: (state) => {
      // console.log("setIsExpire")
      return {
        ...state,
        isExpire: true
      };
    },
    setIsExpireTime: (state) => {
      // console.log("setIsExpireTime")
      return {
        ...state,
        expireTime: state.expireTime - 1,
      };
    },
    setRenew: (state) => {
      // console.log("renew")
      return {
        ...state,
        isExpire: false,
        expireTime: expireTime
      }
    }
  },
});

// Action creators are generated for each case reducer function
export const { setIsExpire, setIsExpireTime, setDefaultState, setRenew } =
  isExpireSession.actions;

export default isExpireSession.reducer;
