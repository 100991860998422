import React from "react";
const ToggleIframe = ({ isHidden, children }) => {
  const fullScreenStyle = {
    width: "100%",
    height: "96%",
    zIndex: "15",
    position: "absolute" as "absolute",
    transition: "top 1s ease-in-out",
    top: `${100 * isHidden + 6}svh`,
    backgroundColor: "#f7f9ff",
    // paddingBottom: "12vh",
    boxSizing: "border-box" as "border-box",
  };
  return <div style={fullScreenStyle}>{children}</div>;
};
export default ToggleIframe;
