import styled from "styled-components";
import { Flex } from "../shared/Flex";
import React from "react";
import Logo from "./Logo";
import StartButton from "./StartButton";
import { Helper } from "utils/helper/helper.helper";
import { useMutation, useQueryClient } from "react-query";
import { measureService } from "services/measureService.service";
import { env_server } from "../../data/env/envKey.env";
import { changeFinishMeasureState, changeLoadingProcessingMeasureDataState } from "store/measureStatus/measureStatusSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import BuildCircleTwoToneIcon from '@mui/icons-material/BuildCircleTwoTone';
import { VitalElementArray } from "data/constant/vitalConstraint";
import { enableUploadPDF } from "store/uploadFile/uploadFileSession";
import { setIsReviewHidden } from "store/popupStateManagement/popupStateManagementSlice";
import { useSessionTimer } from "hooks/useSessionTimer";

const Wrapper = styled(Flex)`
  width: 100%;
  position: relative;
  align-items: center;
  /* min-height: 6vh; */
  z-index: 16;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.085);
  background: #ffffff;
  height: 6%;
`;

const WrapperIframe = styled(Flex)`
  width: 100%;
  position: relative;
  align-items: center;
  /* min-height: 6vh; */
  z-index: 2;
  height: 6%;
`;

const TestButton = styled.button`
  @media (max-width: 700px) {
    width: 20%;
  }

  @media (max-width: 1700px) {
    left: 1vw;
  }

  justify-content: center;
  align-items: center;
  display: flex;
  color: #8ec73d;
  border: 2px solid #8ec73d;
  border-radius: 3px;
  position: absolute;
  right: 7vw;
  font-size: 2vh;
  margin-right: 200px;
  height: 80%;
  width: 100px;

  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-weight: 600;
  margin: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;

  &:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow 0.2s;
  }

  &:active {
    background-color: #f7f7f7;
    border-color: #8ec73d;
    transform: scale(0.96);
  }

  &:disabled {
    border-color: #dddddd;
    color: #dddddd;
    cursor: not-allowed;
    opacity: 1;
  }
`;

const TestIconSX = {
  "@media (max-width: 700px)": {
    width: "10%"
  },

  "@media (max-width: 1700px)": {
    left: "1vw",
  },

  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  color: "#8ec73d",
  position: "absolute",
  right: "7vw",
  fontSize: "2vh",
  marginRight: "200px",
  height: "80%",
  width: "50px",
  backgroundColor: "#ffffff",
  boxSizing: "border-box",
  cursor: "pointer",
  margin: 0,
  outline: "none",
}

const BackButton = styled.button`
  @media (max-width: 700px) {
    width: 20%;
    left: 1vw;
  }

  @media (max-width: 1700px) {
    right: 10vw;
  }
  
  @media (max-width: 1024px) {
    right: 15vw;
  }
  justify-content: center;
  align-items: center;
  display: flex;
  color: #8ec73d;
  border: 2px solid #8ec73d;
  border-radius: 3px;
  position: absolute;
  right: 13vw;
  font-size: 2vh;
  height: 80%;
  width: 100px;

  background-color: #ffffff;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-weight: 600;
  margin: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s,
    transform 0.1s;
  user-select: none;
  -webkit-user-select: none;

  &:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow 0.2s;
  }

  &:active {
    background-color: #f7f7f7;
    border-color: #8ec73d;
    transform: scale(0.96);
  }

  &:disabled {
    border-color: #dddddd;
    color: #dddddd;
    cursor: not-allowed;
    opacity: 1;
  }
`;

let measureFirstTime = false;
export default function TopBar({
  isMeasuring,
  isLoading,
  isVideoReady,
  isShown,
}) {
  const dispatch = useDispatch()
  const queryClient = useQueryClient();
  const finishMeasure = useSelector((state: RootState) => state.measureStatus.finishMeasure)
  measureFirstTime = measureFirstTime || finishMeasure;
  const { token, iframe, filter } = useSelector((state: RootState) => state.paramData);

  const useAddKeyPairValue = useMutation((data: any) => measureService.addKeyPairValue(data), {
    onSuccess: async (data) => {
      queryClient.invalidateQueries({ queryKey: ["keyPairValue", token] });
      dispatch(enableUploadPDF());
      dispatch(setIsReviewHidden(false));
    },
    onError: (error) => {
      console.log(error);
    }
  })

  const handleTestButtonClicked = () => {
    //measure again
    if (finishMeasure) {
      dispatch(changeFinishMeasureState(false));
      dispatch(setIsReviewHidden(true));
    }
    //finish measure -> popup iframe (test logic)
    else {
      dispatch(changeFinishMeasureState(true));
      dispatch(changeLoadingProcessingMeasureDataState(true));

      if (filter.length != 0) {
        var randomVitalSignData = Helper.GetRandomVitalsign(filter);
      } else {
        var randomVitalSignData = Helper.GetRandomVitalsign(VitalElementArray);
      }

      try {
        const dataSending: {} = {
          ID: `3;${token}`,
          KeyPair: Helper.ModifiedRequestData(randomVitalSignData),
        };
        useAddKeyPairValue.mutate(dataSending);
      } catch (err) {
        console.log(err);
      };
    }
  }

  const handleBackButtonClicked = () => {
    window.location.href = `${env_server.REDIRECT_URL}`;
  };

  return (
    <>
      {!(iframe) && (
        <Wrapper>
          <Logo />
          {isShown && isVideoReady && (
            <StartButton
              isLoading={isLoading}
              isMeasuring={isMeasuring}
            />
          )}
          {
            // (env_server.SOURCE == CONSTANT_VALUE.dev) &&
            isShown && isVideoReady && (
              <BuildCircleTwoToneIcon sx={TestIconSX} onClick={handleTestButtonClicked} />
            )
          }
          {/* {measureFirstTime && env_server.REDIRECT_URL && env_server.REDIRECT_URL != "" && (
            <BackButton onClick={handleBackButtonClicked}>BACK</BackButton>
          )} */}
        </Wrapper>
      )}
      {iframe && (
        <WrapperIframe>
          {isShown && isVideoReady && (
            <StartButton
              isLoading={isLoading}
              isMeasuring={isMeasuring}
            />
          )}
          {/* {measureFirstTime && env_server.REDIRECT_URL && env_server.REDIRECT_URL != "" && (
            <BackButton onClick={handleBackButtonClicked}>BACK</BackButton>
          )} */}
          {
            // (env_server.SOURCE == CONSTANT_VALUE.dev) &&
            isShown && isVideoReady && (
              <BuildCircleTwoToneIcon sx={TestIconSX} onClick={handleTestButtonClicked} />
            )
          }
        </WrapperIframe>
      )}
    </>
  );
}
