import React from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";
import WarningIcon from "../../assets/icon/warning.svg";
import { ErrorCodeDetail } from "data/constant/vitalDescription";

const Wrapper = styled(Flex)`
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  text-align: center;
  position: absolute;
  /* bottom: 5vh; */
  height: fit-content;
  width: 90%;
  max-width: 700px;
  background-color: #fff7df;
  border-radius: 20px;
  border: 0.35vh solid #ffbf00;
  word-wrap: break-word;
  z-index: 150;
`;

const Message = styled.div`
  font-size: 2vh;
  font-weight: 600;
  color: #dbac20;
  text-align: center;
  @media (max-width: 700px){
    font-size: 1.75vh;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  height: 6vh;
`;

const WarningCode = styled.div`
  color: #ffbf00;
  font-weight: 600;
  font-size: 2vh;
`;

const WarningAlert = ({ message }) => {
  if (!message) {
    return null;
  }

  console.log(message.split(" ").pop());

  return (
    <Wrapper>
      <IconContainer>
        <Icon src={WarningIcon} />
        <WarningCode>{`${message.split(" ").pop()}`}</WarningCode>
      </IconContainer>
      <Message>{ErrorCodeDetail[`${message.split(" ").pop()}`]}</Message>
    </Wrapper>
  );
};
export default WarningAlert;
