import { createSlice } from '@reduxjs/toolkit'

export interface ParamDataState {
  token: string,
  autostart: boolean,
  iframe: boolean,
  filter: string[],
}

const initialState: ParamDataState = {
  token: "",
  autostart: false,
  iframe: false,
  filter: [],
}

export const paramDataSlice = createSlice({
  name: 'paramData',
  initialState,
  reducers: {
    setToken: (state, action) => {
      const { token } = action.payload;
      state.token = token;
    },
    setFilterData: (state, action) => {
      const { filterArray } = action.payload;
      state.filter = filterArray;
    },
    changeAutoStartEnableState: (state, action) => {
      const autoStartState = action.payload;
      state.autostart = autoStartState;
    },
    changeIframeEnableState: (state, action) => {
      const iframeState = action.payload;
      state.iframe = iframeState;
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setToken,
  setFilterData,
  changeAutoStartEnableState,
  changeIframeEnableState,
} = paramDataSlice.actions

export default paramDataSlice.reducer