import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

export let reduxDataGlobal: {
  HostnameID: string,
};
export default function ReduxDataGlobal() {
  // const hostnameID = useSelector((state: RootState) => state.hostnameID.hostnameID);

  // reduxDataGlobal = {
  //   HostnameID: hostnameID
  // }

  return (
    <div></div>
  )
}
