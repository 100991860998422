import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import monitor, {
  AlertData,
  DeviceOrientation,
  EnabledVitalSigns,
  FaceSessionOptions,
  Gender,
  SUBJECT_DEMOGRAHIC_THRESHOLD,
  HealthMonitorCodes,
  HealthMonitorSession,
  OfflineMeasurements,
  SessionState,
  VitalSigns,
  VitalSignsResults,
} from "@binah/web-sdk";
import { InfoType, InfoData } from "../types";
import { Helper } from "utils/helper/helper.helper";
import { PROFILE_DATA, UNKNOWN_DATA, VITALSIGN_DATE, VITALSIGN_KEY, VITALSIGN_LOGIC } from "data/constant/vitalConstraint";
import { measureService } from "services/measureService.service";
import { UseQueryResult, useMutation, useQueryClient } from "react-query";
import { ReturnResult } from "data/model/returnResult.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { measureSessionInit } from "store/measureSession/measureSessionSlice";
import { enableUploadPDF } from "store/uploadFile/uploadFileSession";
import { setIsReviewHidden } from "store/popupStateManagement/popupStateManagementSlice";

const useMonitor = (
  video: MutableRefObject<HTMLVideoElement>,
  cameraId: string,
  productId: string,
  startMeasuring: boolean
) => {
  const finishMeasure = useSelector((state: RootState) => state.measureStatus.finishMeasure)
  const [session, setSession] = useState<HealthMonitorSession>();
  const [sessionState, setSessionState] = useState<SessionState>();
  const [isMonitorReady, setIsMonitorReady] = useState<boolean>();
  const [enabledVitalSigns, setEnabledVitalSigns] =
    useState<EnabledVitalSigns>();
  const [offlineMeasurements, setOfflineMeasurements] =
    useState<OfflineMeasurements>();
  const [vitalSigns, setVitalSigns] = useState<VitalSigns | null>();
  const [error, setError] = useState<AlertData>({ code: -1 });
  const [warning, setWarning] = useState<AlertData>({ code: -1 });
  const [info, setInfo] = useState<InfoData>({ type: InfoType.NONE });

  const isDismissing = useRef<boolean>(false);

  const setInfoWithDismiss = useCallback(
    (info: InfoData, seconds?: number) => {
      if (!isDismissing.current) {
        setInfo(info);
        if (seconds) {
          isDismissing.current = true;
          setTimeout(() => {
            setInfo({ type: InfoType.NONE });
            isDismissing.current = false;
          }, seconds * 1000);
        }
      }
    },
    [InfoType, setInfo, info, isDismissing, isDismissing.current],
  );

  const updateVitalSigns = useCallback((vitalSigns) => {
    setVitalSigns((prev) => ({
      ...prev,
      ...vitalSigns,
    }));
  }, []);

  const onVitalSign = useCallback((vitalSign: VitalSigns) => {
    updateVitalSigns(vitalSign);
  }, []);

  const onFinalResults = useCallback((vitalSignsResults: VitalSignsResults) => {
    updateVitalSigns(vitalSignsResults.results);
  }, []);

  const onError = useCallback((errorData: AlertData) => {
    setError(errorData);
  }, [])

  const onWarning = (warningData: AlertData) => {
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_MISDETECTION_DURATION_EXCEEDS_LIMIT_WARNING
    ) {
      setVitalSigns(null);
    }
    if (
      warningData.code ===
      HealthMonitorCodes.MEASUREMENT_CODE_UNSUPPORTED_ORIENTATION_WARNING
    ) {
      setInfo({
        message: `Warning: ${warningData.code}`,
        type: InfoType.INSTRUCTION,
      });
    } else {
      setWarning(warningData);
    }
  };

  const onStateChange = useCallback((state: SessionState) => {
    setSessionState(state);
    if (state === SessionState.MEASURING) {
      setVitalSigns(null);
    }
  }, []);

  const onEnabledVitalSigns = useCallback((vitalSigns: EnabledVitalSigns) => {
    setEnabledVitalSigns(vitalSigns);
  }, []);

  const onOfflineMeasurement = useCallback(
    (offlineMeasurements: OfflineMeasurements) => {
      setOfflineMeasurements(offlineMeasurements);
    },
    []
  );

  const onActivation = useCallback((activationId: string) => {
    // the device has been activated with activationId
  }, []);

  const onFaceDetected = useCallback((isRect: boolean) => {
    if (!isRect) {
      setInfo({
        type: InfoType.INSTRUCTION,
        message: 'Face not detected',
      });
    } else {
      setInfoWithDismiss({ type: InfoType.NONE });
    }
  }, []);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const token = useSelector((state: RootState) => state.paramData.token);

  const licenseKey = useSelector((state: RootState) => state.keyDurationSession.license);
  const processingTime = parseInt(useSelector((state: RootState) => state.keyDurationSession.processingTime));
  const filter = useSelector((state: RootState) => state.paramData.filter);

  const {
    data: patientData,
    status: patientDataStatus,
    isFetching: patientDataisFetching,
    error: patientDataError
  }: UseQueryResult<ReturnResult<string>, Error> = measureService.useGetKeyPairValue(token);

  const useAddKeyPairValue = useMutation((variable: any) =>
    measureService.addKeyPairValue(variable.dataSending), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["keyPairValue", token] });
      dispatch(enableUploadPDF());
      dispatch(setIsReviewHidden(false));
    },
    onError: (error) => {
      console.log(error);
    }
  })

  useEffect(() => {
    (async () => {
      try {
        if (licenseKey) {
          await monitor.initialize({
            licenseKey,
            licenseInfo: {
              onEnabledVitalSigns,
              onOfflineMeasurement,
              onActivation,
            },
          });
          console.log(`Initialized monitor`);
          setIsMonitorReady(true);
          setError({ code: -1 });
        }
      } catch (e) {
        console.error("Error initializing HealthMonitor", e);
        setIsMonitorReady(false);
        setError({ code: e.errorCode });
      }
    })();
  }, [licenseKey, productId]);

  useEffect(() => {
    (async () => {
      try {
        if (!isMonitorReady || !processingTime || !video.current || patientDataisFetching) {
          return;
        }

        sessionState === SessionState.ACTIVE && session.terminate();

        var options: FaceSessionOptions = {
          input: video.current,
          cameraDeviceId: cameraId,
          processingTime,
          onFaceDetected,
          onVitalSign,
          onFinalResults,
          onError,
          onWarning,
          onStateChange,
          orientation: DeviceOrientation.PORTRAIT,
        };

        var VitalsValue: {} = JSON.parse(patientData.result);
        VitalsValue = Helper.ConvertJsonToLowercase(VitalsValue);

        if (VitalsValue && VitalsValue.hasOwnProperty(PROFILE_DATA.Age) && VitalsValue.hasOwnProperty(PROFILE_DATA.Weight) && VitalsValue.hasOwnProperty(PROFILE_DATA.Gender)) {
          var subjectDemographic = {
            age: Number.parseInt(VitalsValue[PROFILE_DATA.Age]),
            weight: Number.parseFloat(VitalsValue[PROFILE_DATA.Weight]),
            gender: Gender[`${(VitalsValue[PROFILE_DATA.Gender]).toUpperCase()}`],
          };

          //Validate the range of age and weight
          if (subjectDemographic.age >= SUBJECT_DEMOGRAHIC_THRESHOLD.age.min && subjectDemographic.age <= SUBJECT_DEMOGRAHIC_THRESHOLD.age.max
            && subjectDemographic.weight >= SUBJECT_DEMOGRAHIC_THRESHOLD.weight.min && subjectDemographic.weight <= SUBJECT_DEMOGRAHIC_THRESHOLD.weight.max) {
            options = {
              ...options,
              /*******************************************************************************
             * For accurate vital signs calculation the user's parameters should be provided.
             * The following is an example of how the parameters are passed to the SDK:
             *
             * subjectDemographic: {age: 35, weight: 75, gender: Gender.MALE}
             *
             * When measuring a new user then a new session must be created using the
             * new user's parameters
             * The parameters are used locally on the device only for the vital sign
             * calculation, and deleted when the session is terminated.
             *******************************************************************************/
              subjectDemographic
            }
          }
        }

        const faceSession = await monitor.createFaceSession(options);
        console.log(`Session created`);
        setSession(faceSession);
        setError({ code: -1 });
        dispatch(measureSessionInit());
      } catch (e) {
        setError({ code: e.errorCode });
        console.error("Error creating a session", e);
      }
    })();
  }, [processingTime, isMonitorReady]);

  const ModifiedVitalSignsData = (vitalSigns: VitalSigns) => {
    //Convert Vitalsign data ex: WR: "10"
    var vitalObj: {} = {};
    if (vitalSigns) {
      if (filter.length != 0) {
        const bloodPressureToDisplay =
          (vitalSigns.bloodPressure?.value?.systolic && vitalSigns.bloodPressure?.value?.diastolic) ? vitalSigns.bloodPressure?.value?.systolic + "/" + vitalSigns.bloodPressure?.value?.diastolic : "--";
        vitalObj[`${VITALSIGN_KEY.WellnessRange}`] = (filter.includes(VITALSIGN_KEY.WellnessRange) && vitalSigns.wellnessIndex) ? vitalSigns.wellnessIndex.value : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.HeartRate}`] = (filter.includes(VITALSIGN_KEY.HeartRate) && vitalSigns.heartRate) ? vitalSigns.heartRate.value : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.BreathingRate}`] = (filter.includes(VITALSIGN_KEY.BreathingRate) && vitalSigns.breathingRate) ? vitalSigns.breathingRate.value : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.Stress}`] = (filter.includes(VITALSIGN_KEY.Stress) && vitalSigns.stressLevel) ? vitalSigns.stressLevel.value : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.HRVariability}`] = (filter.includes(VITALSIGN_KEY.HRVariability) && vitalSigns.sdnn) ? vitalSigns.sdnn.value : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.Parasympathetic_Activity}`] = (filter.includes(VITALSIGN_KEY.Parasympathetic_Activity) && vitalSigns.pnsIndex) ? vitalSigns.pnsIndex.value : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.BloodPressure}`] = (filter.includes(VITALSIGN_KEY.BloodPressure) && bloodPressureToDisplay) ? bloodPressureToDisplay : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.Hemoglobin}`] = (filter.includes(VITALSIGN_KEY.Hemoglobin) && vitalSigns.hemoglobin) ? vitalSigns.hemoglobin.value : UNKNOWN_DATA.NaN;

        vitalObj[`${VITALSIGN_KEY.HemoglobinA1c}`] = (filter.includes(VITALSIGN_KEY.HemoglobinA1c) && vitalSigns.hemoglobinA1c) ? vitalSigns.hemoglobinA1c.value : UNKNOWN_DATA.NaN;

        // vitalObj[`${VITALSIGN_DATE.MeasureDate}`] = new Date();

        // if (env_server.SOURCE == CONSTANT_VALUE.callFlow) {
        //   vitalObj[`${VITALSIGN_DATE.DateTime}`] = new Date();
        // }
      } else {
        const bloodPressureToDisplay =
          (vitalSigns.bloodPressure?.value?.systolic && vitalSigns.bloodPressure?.value?.diastolic) ? vitalSigns.bloodPressure?.value?.systolic + "/" + vitalSigns.bloodPressure?.value?.diastolic : "--";

        vitalObj[`${VITALSIGN_KEY.WellnessRange}`] = vitalSigns.wellnessIndex.value ? vitalSigns.wellnessIndex.value : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.HeartRate}`] = vitalSigns.heartRate ? vitalSigns.heartRate.value : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.BreathingRate}`] = vitalSigns.breathingRate ? vitalSigns.breathingRate.value : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.Stress}`] = vitalSigns.stressLevel ? vitalSigns.stressLevel.value : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.HRVariability}`] = vitalSigns.sdnn ? vitalSigns.sdnn.value : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.Parasympathetic_Activity}`] = vitalSigns.pnsIndex ? vitalSigns.pnsIndex.value : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.BloodPressure}`] = vitalSigns.bloodPressure ? bloodPressureToDisplay : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.Hemoglobin}`] = vitalSigns.hemoglobin ? vitalSigns.hemoglobin.value : UNKNOWN_DATA.NaN;
        vitalObj[`${VITALSIGN_KEY.HemoglobinA1c}`] = vitalSigns.hemoglobinA1c ? vitalSigns.hemoglobinA1c.value : UNKNOWN_DATA.NaN;

        // vitalObj[`${VITALSIGN_DATE.MeasureDate}`] = new Date();

        // if (env_server.SOURCE == CONSTANT_VALUE.callFlow) {
        //   vitalObj[`${VITALSIGN_DATE.DateTime}`] = new Date();
        // }
      }

      //Completed flag logic
      vitalObj[`${VITALSIGN_LOGIC.Completed}`] = true;
    }
    return vitalObj;
  };

  useEffect(() => {
    if (startMeasuring) {
      if (sessionState === SessionState.ACTIVE) {
        session.start();
        setError({ code: -1 });
      }
    } else {
      if (vitalSigns && finishMeasure) {
        const dataSending: {} = {
          ID: `3;${token}`,
          KeyPair: Helper.ModifiedRequestData(ModifiedVitalSignsData(vitalSigns)),
        };
        useAddKeyPairValue.mutate({ dataSending })
      }
      if (!finishMeasure && sessionState == SessionState.STOPPING) {
        console.log("Unexpected Stop!")
      }
      sessionState === SessionState.MEASURING && session.stop();
    }
  }, [startMeasuring]);

  return {
    sessionState,
    vitalSigns: {
      heartRate: {
        value: vitalSigns?.heartRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledHeartRate,
      },
      breathingRate: {
        value: vitalSigns?.breathingRate?.value,
        isEnabled: enabledVitalSigns?.isEnabledBreathingRate,
      },
      stress: {
        value: vitalSigns?.stressLevel?.value,
        isEnabled: enabledVitalSigns?.isEnabledStressLevel,
      },
      hrvSdnn: {
        value: vitalSigns?.sdnn?.value,
        isEnabled: enabledVitalSigns?.isEnabledSdnn,
      },
      spo2: {
        value: null, //TODO Spo2 is currently disabled by algo
        isEnabled: false, //enabledVitalSigns?.isEnabledSpo2,
      },
      prq: {
        value: vitalSigns?.prq?.value,
        isEnabled: enabledVitalSigns?.isEnabledPrq,
      },
      bloodPressure: {
        value: vitalSigns?.bloodPressure?.value,
        isEnabled: enabledVitalSigns?.isEnabledBloodPressure,
      },
      pns: {
        value: vitalSigns?.pnsZone?.value,
        isEnabled: enabledVitalSigns?.isEnabledPnsZone,
      },
      hemoglobin: {
        value: vitalSigns?.hemoglobin?.value,
        isEnabled: enabledVitalSigns?.isEnabledHemoglobin,
      },
      hemoglobinA1c: {
        value: vitalSigns?.hemoglobinA1c?.value,
        isEnabled: enabledVitalSigns?.isEnabledHemoglobinA1c,
      },
    },
    offlineMeasurements,
    error,
    warning,
    info,
  };
};

export default useMonitor;