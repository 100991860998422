import { API_PATH } from "data/api/APISetting";
import { useQuery } from "react-query";
import { IEnvServer } from "data/interface/env_server.interface";
import { ReturnResult } from "data/model/returnResult.model";
import { IConfigData } from "data/interface/config.interface";
import { useSelector } from 'react-redux'
import { RootState } from 'store/store';
import axiosInstance from "./axiosInstance.service";

const getSourceConfig = async (): Promise<ReturnResult<IEnvServer>> => {
  const response = await axiosInstance.get(`${API_PATH.getSourceConfig}`);
  return response.data;
}

const useGetSourceConfig = () => {
  return useQuery<ReturnResult<IEnvServer>, Error>({
    queryKey: ['useGetSourceConfig'],
    queryFn: async () => {
      const data: ReturnResult<IEnvServer> = await getSourceConfig();
      return data;
    },
    refetchOnWindowFocus: false,
    retry: 3,
  })
}

export const initialService = {
  useGetSourceConfig,
};