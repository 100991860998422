import React from "react";
import { SOURCE_PARAM } from "data/env/envKey.env";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { changeAutoStartEnableState, changeIframeEnableState, setFilterData, setToken } from "store/paramData/paramDataSlice";
import CryptoJS from "crypto-js";

export default function GetParamRoute() {
  window.localStorage.clear();
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);

  if (window.location.href.search("vitalsign") != -1) {
    const token = params.get(SOURCE_PARAM.tokenParam) ? params.get(SOURCE_PARAM.tokenParam) : null;
    if (Boolean(token)) {
      dispatch(setToken({ token }));
    }

    const is_iframe = params.get(SOURCE_PARAM.iframeParam) ? params.get(SOURCE_PARAM.iframeParam) : null;
    if (Boolean(is_iframe)) {
      dispatch(changeIframeEnableState(true));
    }

    const is_autostart = params.get(SOURCE_PARAM.autostartParam) ? params.get(SOURCE_PARAM.autostartParam) : null;
    if (Boolean(is_autostart)) {
      dispatch(changeAutoStartEnableState(true));
    }

    const filter = params.get(SOURCE_PARAM.filterParam) ? params.get(SOURCE_PARAM.filterParam) : null;
    if (filter) {
      const decodedString = (CryptoJS.enc.Base64.parse(filter)).toString(CryptoJS.enc.Utf8);
      const filterArray = decodedString.split(",");
      dispatch(setFilterData({ filterArray }));
    }

    // if (env_server.SOURCE == CONSTANT_VALUE.callFlow) {
    //   const token_testmode = params.get(process.env.REACT_APP_BYPASS_CHECKING_KEY) ? params.get(process.env.REACT_APP_BYPASS_CHECKING_KEY) : null;
    //   localStorage.setItem(process.env.REACT_APP_BYPASS_CHECKING_KEY, token_testmode);
    // }
  }

  return <Outlet />;
}