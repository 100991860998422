import React from 'react'
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 5%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.707);
  text-align: center;
  bottom: 0;
  @media (max-width: 375px){
    font-size: 10px;
  }

  @media (max-width: 240px){
    font-size: 7px;
  }
`;

export default function BottomBar() {
  return (
    <Wrapper>© 2023 - PRIMAS GROUP</Wrapper>
  )
}
