export const API_PATH = {
    //CALLFLOW
    //GET - with params
    // getSettingByKey: "${API_URL}/settingproxy/proxy/commonservice/settings",
    //POST - with body
    // addKeyPairValue: "${API_URL}/settingproxy/proxy/screenpop/callerdata",
    //GET - with params
    // getKeyPairValue: "${API_URL}/settingproxy/proxy/screenpop/keypair",
    //GET - with params
    validation: "/settingproxy/validation/screenpop/keypair",
    //GET - with params
    validationCompleted: "/settingproxy/validation/completed",

    //VITALRPM
    //PrimasCRM

    //GET - with params
    getSettingByKey: "/api/proxy/VitalSettingManagement/GetSettingByKey",
    //POST - with body
    addKeyPairValue: "/api/proxy/VitalIVRKeyPairManagement/SaveIVRKeyPair",
    //GET - with params
    getKeyPairValue: "/api/proxy/VitalIVRKeyPairManagement/GetCallerInfo",
    //GET
    getSourceConfig: "/api/proxy/VitalSettingManagement/GetSourceConfig",
    //GET
    uploadFile: "/api/proxy/file/upload",
    //GET
    downloadFile: "/api/proxy/file/download",
}