import React from 'react'
import { env_server } from 'data/env/envKey.env';
import { Navigate, Outlet } from 'react-router-dom';
import { CONSTANT_VALUE } from '../../data/env/envKey.env';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { UseQueryResult } from 'react-query';
import { ReturnResult } from 'data/model/returnResult.model';
import { measureService } from 'services/measureService.service';

export default function ValidateRoute() {
  const token = useSelector((state: RootState) => state.paramData.token);
  const {
    data: patientData,
    status: patientDataStatus,
    isFetching: patientDataisFetching,
    error: patientDataError,
  }: UseQueryResult<ReturnResult<string>, Error> = measureService.useGetKeyPairValueForValidation(token);

  if (patientDataError) {
    return <Navigate to="/404" />;
  }

  if (patientDataisFetching) {
    return null;
  }

  //validate URL
  //VITALRPM
  if (env_server.SOURCE == CONSTANT_VALUE.vitalRPM) {
    if (window.location.href.search("vitalsign") != -1) {
      // const token = useSelector((state: RootState) => state.paramData.token);
      if (token) {
        //validate PatientID
        //Because there will be some case that the invalid token like ?token=? and the server will act like the URL isn't match and return the index.html of the frontend
        if (!patientData.hasOwnProperty('result') || patientData.result == "{}") {
          return <Navigate to="/401" />;
        }

        //validate the session is expired or not
        //Check if the patient data have the property HF or Completed or not.
        //If it has -> expired, if it not -> go to measure screen

        if (JSON.parse(patientData.result).hasOwnProperty("HR")
          || JSON.parse(patientData.result).hasOwnProperty("Completed")) {
          return <Navigate to="/expired" />;
        }
      }
      else {
        return <Navigate to="/404" />;
      }
    }
  }
  //CALLFLOW
  // else if (env_server.SOURCE == CONSTANT_VALUE.callFlow) {
  //   if (window.location.href.search("vitalsign") != -1) {
  //     const token = localStorage.getItem("token");
  //     if (token != "null") {
  //       //check bypass
  //       // if (token != process.env.REACT_APP_BYPASS_TOKEN) {
  //       if (!VitalBypassKey.includes(token)) {
  //         //validate callerid
  //         const validateToken = await axiosInstance.get(`${API_PATH.validation}/${token}`);
  //         if (!(validateToken.data.result ? true : false) && window.location.href.search("result") == -1) {
  //           navigate("/401");
  //         }
  //         //validate completed
  //         if (localStorage.getItem("vitalsign") == null) {
  //           if (window.location.href.search("vitalsign/result") != -1) {
  //             navigate("/404");
  //           }
  //           const dataResponse = await axiosInstance.get(`${API_PATH.validationCompleted}/${token}`);

  //           if (dataResponse.data.result == true &&
  //             //bypass review screen
  //             window.location.href.search("vitalsign/review") == -1
  //           ) {
  //             navigate("/expired");
  //           }
  //         }

  //         //callAPIAccess
  //         if ((window.location.href.search("vitalsign") != -1) && (window.location.href.search("result") == -1) &&
  //           (window.location.href.search("vitalsign/review") == -1)) {
  //           const dataSending: {} = {
  //             ID: `3;${token}`,
  //             KeyPair: "UserAccess;true",
  //           };
  //           await axiosInstance.post(`${API_PATH.addKeyPairValue}`, dataSending);
  //         }
  //         //If nothing invalid appears, turn the validate success flag on
  //       }
  //     }
  //     else {
  //       navigate("404");
  //     }
  //   }
  // }

  return <Outlet />;
}