import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { useMutation } from "react-query";
import { measureService } from "services/measureService.service";
import { VITALSIGN_LOGIC } from "data/constant/vitalConstraint";
import { changeStartMeasureState } from "store/measureStatus/measureStatusSlice";

// export interface IStartButton {
//   /**
//    *  Displays a spinner when isLoading is true
//    */
//   isLoading;
//   /**
//    *  function triggered on button click
//    */
//   onClick: () => void;
//   /**
//    *  Displays whether the current session state is measuring
//    */
//   isMeasuring: boolean;
// }

const ButtonCustom = styled.button`
  @media (max-width: 700px) {
    width: 20%;
  }

  justify-content: center;
  align-items: center;
  display: flex;
  color: #8ec73d;
  border: 2px solid #8ec73d;
  border-radius: 3px;
  position: absolute;
  right: 1vw;
  font-size: 2vh;
  height: 80%;
  width: 100px;

  background-color: #FFFFFF;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
  font-weight: 600;
  margin: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
  user-select: none;
  -webkit-user-select: none;

  &:focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }

  &:active {
    background-color: #F7F7F7;
    border-color: #8ec73d;
    transform: scale(.96);
  }

  &:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
  }
`;

const ButtonCustomTitle = styled.p`
  font-weight: 700;
`;

export default function StartButton({ isLoading, isMeasuring }) {   
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.paramData.token);
  const useAddKeyPairValue = useMutation((data: any) => measureService.addKeyPairValue(data), {
    onSuccess: async (data) => {
      // queryClient.invalidateQueries({ queryKey: ["keyPairValue", token] });
    },
    onError: (error) => {
      console.log(error);
    }
  })

  useEffect(() => {
    // if (env_server.SOURCE == CONSTANT_VALUE.callFlow) {
    //   if (isMeasuring) {
    //     async function callAPI() {
    //       const dataSending: {} = {
    //         ID: `3;${token}`,
    //         KeyPair: "StartMeasure;true",
    //       };
    //       await axios.post(`${API_PATH.addKeyPairValue}`, dataSending);
    //     }
    //     try {
    //       callAPI();
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // }

    if (isMeasuring) {
      const dataSending: {} = {
        ID: `3;${token}`,
        KeyPair: `${VITALSIGN_LOGIC.StartMeasure};true`,
      };
      useAddKeyPairValue.mutate(dataSending);
    }

  }, [isMeasuring]);

  return (
    <>
      <ButtonCustom onClick={() => { dispatch(changeStartMeasureState(true)) }}>
        {isMeasuring ? <ButtonCustomTitle>STOP</ButtonCustomTitle> : <ButtonCustomTitle>START</ButtonCustomTitle>}
      </ButtonCustom>
    </>
  );
}
