import React from "react";
import styled from "styled-components";
import { Flex } from "../shared/Flex";
import loadingGIF from "../../assets/primas/loading.gif"
const LoadingWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #FFF;
  z-index: 35;
`;
const Loading = styled.img`
  height: 13.5vh;
`;

export default function Loader({ opacity }) {
  return (
    <LoadingWrapper style={{
      opacity: Number.parseFloat(opacity),
    }}>
      <Loading src={loadingGIF} />
    </LoadingWrapper>
  );
}
